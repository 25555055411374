import React, { useState, useEffect } from "react";
import { TableCell, Tooltip, Link, Box, CircularProgress } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const isWithinRestrictionPeriod = (startDate, endDate) => {
  if (!startDate || !endDate) return false;

  const currentDate = new Date();
  const restrictionStart = new Date(startDate);
  const restrictionEnd = new Date(endDate);

  return currentDate >= restrictionStart && currentDate <= restrictionEnd;
};

export const DocumentAccessCell = ({ documentData, onDocumentClick }) => {
  const [isRestricted, setIsRestricted] = useState(false);

  useEffect(() => {
    const checkRestriction = () => {
      const restricted = isWithinRestrictionPeriod(
        documentData.documents_restriction_start,
        documentData.documents_restriction_end
      );
      setIsRestricted(restricted);
    };

    checkRestriction();
    const interval = setInterval(checkRestriction, 60000);
    return () => clearInterval(interval);
  }, [documentData]);

  return (
    <TableCell
      sx={{
        fontSize: {
          xs: "8px",
          sm: "9px",
          md: "10px",
        },
        padding: {
          xs: "4px",
          sm: "8px",
          md: "12px",
        },
      }}
    >
      {documentData.doc_link === "#" ? (
        <Tooltip
          title={
            isRestricted
              ? "Document access is currently restricted"
              : "View Document"
          }
        >
          <Link
            onClick={() => !isRestricted && onDocumentClick()}
            sx={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              cursor: isRestricted ? "not-allowed" : "pointer",
              color: isRestricted ? "gray" : "inherit",
              opacity: isRestricted ? 0.6 : 1,
            }}
          >
            <PictureAsPdfIcon
              sx={{
                color: isRestricted ? "gray" : "red",
                marginRight: 1,
              }}
            />
            Course Access Document
          </Link>
        </Tooltip>
      ) : (
        <Tooltip title="You Will Get Access on 9th January 2025">
          <Box
            sx={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              cursor: "not-allowed",
              color: "gray",
            }}
          >
            <PictureAsPdfIcon sx={{ color: "gray", marginRight: 1 }} />
            Course Access Document
          </Box>
        </Tooltip>
      )}
    </TableCell>
  );
};
