// import React, { useState } from "react";

// const Faq = () => {
//   const [activeIndex, setActiveIndex] = useState(null);

//   const toggleFAQ = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   const faqs = [
//     {
//       question: "How do I apply for an internship?",
//       answers: [
//         "Visit our internship portal.",
//         "Submit your resume and cover letter.",
//         "Wait for the HR team to reach out for further steps."
//       ],
//       images: [
//         "https://images.unsplash.com/photo-1673422672504-757a7a57d24a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//       ],
//     },
//     {
//       question: "What are the eligibility criteria for internships?",
//       answers: [
//         "You must be currently enrolled in a university or college.",
//         "Completion of at least two years of your degree program is required.",
//         "Some positions may have additional requirements."
//       ],
//       images: [
//         "https://images.unsplash.com/photo-1698993081947-8a3654303904?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//         "https://images.unsplash.com/photo-1673422672504-757a7a57d24a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//       ],
//     },
//     {
//       question: "Are internships paid or unpaid?",
//       answers: [
//         "Our internships are paid.",
//         "Compensation varies based on the role and location."
//       ],
//       images: [
//         "https://images.unsplash.com/photo-1673422672504-757a7a57d24a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//       ],
//     },
//     {
//       question: "What is the duration of the internship program?",
//       answers: [
//         "The program typically lasts between 8-12 weeks.",
//         "Duration may vary depending on the role and department."
//       ],
//       images: [
//         "https://images.unsplash.com/photo-1698993081947-8a3654303904?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//         "https://images.unsplash.com/photo-1673422672504-757a7a57d24a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//       ],
//     },
//     {
//       question: "Can I work remotely during my internship?",
//       answers: [
//         "Yes, remote opportunities are available.",
//         "The option depends on the specific role and department needs."
//       ],
//       images: [
//         "https://images.unsplash.com/photo-1698993081947-8a3654303904?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//         "https://images.unsplash.com/photo-1673422672504-757a7a57d24a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//       ],
//     },
//   ];

//   return (
//     <div className="max-w-7xl mx-auto p-4">
//       {/* Heading */}
//       <h2 className="text-3xl font-bold mb-8 text-center text-blue-600">
//         Student Internship FAQs
//       </h2>

//       <div className="flex flex-col md:flex-row justify-between">
//         {/* First Column */}
//         <div className="w-full md:w-1/2 p-2">
//           {faqs.slice(0, 3).map((faq, index) => (
//             <div
//               key={index}
//               className="mb-4 bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300"
//             >
//               <button
//                 onClick={() => toggleFAQ(index)}
//                 className={`w-full text-left flex justify-between py-4 px-4 font-medium text-md ${
//                   activeIndex === index
//                     ? "bg-blue-500 text-white"
//                     : "bg-teal-100 text-blue-900"
//                 } transition-all duration-300`}
//               >
//                 <span>{faq.question}</span>
//                 <span>{activeIndex === index ? "-" : "+"}</span>
//               </button>
//               <div
//                 className={`overflow-hidden transition-all duration-300 ease-in-out ${
//                   activeIndex === index ? "max-h-96" : "max-h-0"
//                 }`}
//               >
//                 <div className="px-4 py-2 text-gray-700 bg-teal-50">
//                   <ul className="list-disc pl-5">
//                     {faq.answers.map((answer, answerIndex) => (
//                       <li key={answerIndex}>{answer}</li>
//                     ))}
//                   </ul>
//                   {activeIndex === index && faq.images && (
//                     <div
//                       className={`mt-4 ${
//                         faq.images.length > 1 ? "grid grid-cols-2 gap-4" : ""
//                       }`}
//                     >
//                       {faq.images.map((image, imgIndex) => (
//                         <img
//                           key={imgIndex}
//                           src={image}
//                           alt={`FAQ ${index} illustration ${imgIndex + 1}`}
//                           className={`w-full h-48 object-cover rounded-lg ${
//                             faq.images.length > 1 ? "col-span-1" : ""
//                           }`}
//                         />
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>

//         {/* Second Column */}
//         <div className="w-full md:w-1/2 p-2">
//           {faqs.slice(3).map((faq, index) => (
//             <div
//               key={index + 3}
//               className="mb-4 bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300"
//             >
//               <button
//                 onClick={() => toggleFAQ(index + 3)}
//                 className={`w-full text-left flex justify-between py-4 px-4 font-medium text-md ${
//                   activeIndex === index + 3
//                     ? "bg-blue-500 text-white"
//                     : "bg-teal-100 text-blue-900"
//                 } transition-all duration-300`}
//               >
//                 <span>{faq.question}</span>
//                 <span>{activeIndex === index + 3 ? "-" : "+"}</span>
//               </button>
//               <div
//                 className={`overflow-hidden transition-all duration-300 ease-in-out ${
//                   activeIndex === index + 3 ? "max-h-96" : "max-h-0"
//                 }`}
//               >
//                 <div className="px-4 py-2 text-gray-700 bg-teal-50">
//                   <ul className="list-disc pl-5">
//                     {faq.answers.map((answer, answerIndex) => (
//                       <li key={answerIndex}>{answer}</li>
//                     ))}
//                   </ul>
//                   {activeIndex === index + 3 && faq.images && (
//                     <div
//                       className={`mt-4 ${
//                         faq.images.length > 1 ? "grid grid-cols-2 gap-4" : ""
//                       }`}
//                     >
//                       {faq.images.map((image, imgIndex) => (
//                         <img
//                           key={imgIndex}
//                           src={image}
//                           alt={`FAQ ${index + 3} illustration ${imgIndex + 1}`}
//                           className={`w-full h-48 object-cover rounded-lg ${
//                             faq.images.length > 1 ? "col-span-1" : ""
//                           }`}
//                         />
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Faq;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { BASE_URL } from "../../services/configUrls";

// const Faq = () => {
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [faqs, setFaqs] = useState([]);
//   const [error, setError] = useState(null);
//   const token = localStorage.getItem("accessToken");

//   const toggleFAQ = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   useEffect(() => {
//     const fetchFaqs = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}/faq/getFaq`, {
//           headers: {
//             Authorization: `Bearer ${token}`, // Replace with actual token
//           },
//         });
//         setFaqs(response.data);
//       } catch (err) {
//         setError("Failed to fetch FAQs. Please try again.");
//       }
//     };

//     fetchFaqs();
//   }, []);

//   return (
//     <div className="max-w-7xl mx-auto p-4">
//       <h2 className="text-3xl font-bold mb-8 text-center text-blue-600">
//         Student Internship FAQs
//       </h2>

//       {error && <p className="text-red-500 text-center">{error}</p>}

//       <div className="flex flex-col md:flex-row">
//         <div className="w-full md:w-full p-2">
//           {faqs.slice(0, Math.ceil(faqs.length / 2)).map((faq, index) => (
//             <div
//               key={index}
//               className="mb-4 bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300"
//             >
//               <button
//                 onClick={() => toggleFAQ(index)}
//                 className={`w-full text-left flex justify-between py-4 px-4 font-medium text-md ${
//                   activeIndex === index
//                     ? "bg-blue-500 text-white"
//                     : "bg-teal-100 text-blue-900"
//                 } transition-all duration-300`}
//               >
//                 <span>{faq.question}</span>
//                 <span>{activeIndex === index ? "-" : "+"}</span>
//               </button>
//               <div
//                 className={`overflow-hidden transition-all duration-300 ease-in-out ${
//                   activeIndex === index ? "max-h-96" : "max-h-0"
//                 }`}
//               >
//                 <div className="px-4 py-2 text-gray-700 bg-teal-50">
//                   <ul className="list-disc pl-5">
//                     {faq.answers.map((answer, answerIndex) => (
//                       <li key={answerIndex}>{answer}</li>
//                     ))}
//                   </ul>
//                   {activeIndex === index && faq.images && (
//                     <div
//                       className="mt-4 flex gap-4 overflow-y-auto h-48"
//                       style={{
//                         scrollbarWidth: "thin",
//                         scrollbarColor: "#4A90E2 #D1E8FF",
//                       }}
//                     >
//                       {faq.images.map((image, imgIndex) => (
//                         <img
//                           key={imgIndex}
//                           src={image}
//                           alt={`FAQ ${index + 3} illustration ${imgIndex + 1}`}
//                           className={`w-full h-48 object-cover  rounded-lg ${
//                             faq.images.length > 1 ? "col-span-1" : ""
//                           }`}
//                         />
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>

//         <div className="w-full md:w-full p-2">
//           {faqs.slice(Math.ceil(faqs.length / 2)).map((faq, index) => (
//             <div
//               key={index + Math.ceil(faqs.length / 2)}
//               className="mb-4 bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300"
//             >
//               <button
//                 onClick={() => toggleFAQ(index + Math.ceil(faqs.length / 2))}
//                 className={`w-full text-left flex justify-between py-4 px-4 font-medium text-md ${
//                   activeIndex === index + Math.ceil(faqs.length / 2)
//                     ? "bg-blue-500 text-white"
//                     : "bg-teal-100 text-blue-900"
//                 } transition-all duration-300`}
//               >
//                 <span>{faq.question}</span>
//                 <span>
//                   {activeIndex === index + Math.ceil(faqs.length / 2)
//                     ? "-"
//                     : "+"}
//                 </span>
//               </button>
//               <div
//                 className={`overflow-hidden transition-all duration-300 ease-in-out ${
//                   activeIndex === index + Math.ceil(faqs.length / 2)
//                     ? "max-h-96"
//                     : "max-h-0"
//                 }`}
//               >
//                 <div className="px-4 py-2 text-gray-700 bg-teal-50">
//                   <ul className="list-disc pl-5">
//                     {faq.answers.map((answer, answerIndex) => (
//                       <li key={answerIndex}>{answer}</li>
//                     ))}
//                   </ul>
//                   {activeIndex === index + Math.ceil(faqs.length / 2) &&
//                     faq.images && (
//                       <div
//                         className="mt-4 flex gap-4 overflow-y-auto h-48"
//                         style={{
//                           scrollbarWidth: "thin",
//                           scrollbarColor: "#4A90E2 #D1E8FF",
//                         }}
//                       >
//                         {faq.images.map((image, imgIndex) => (
//                           <img
//                             key={imgIndex}
//                             src={image}
//                             alt={`FAQ ${
//                               index + Math.ceil(faqs.length / 2)
//                             } illustration ${imgIndex + 1}`}
//                             className={`w-full h-48 object-cover  rounded-lg ${
//                               faq.images.length > 1 ? "col-span-1" : ""
//                             }`}
//                           />
//                         ))}
//                       </div>
//                     )}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Faq;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../services/configUrls";

// Loader Component
const LoaderSVG = () => (
  <svg
    className="animate-spin h-5 w-5 text-blue-500"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

// Image Loading Skeleton Component
const ImageLoadingSkeleton = () => (
  <div className="animate-pulse flex space-x-4 mt-4">
    <div className="h-48 w-full bg-gray-200 rounded-lg"></div>
  </div>
);

// Error Message Component
const ErrorMessage = ({ message }) => (
  <div className="text-red-500 text-center p-4 bg-red-50 rounded-lg mb-4">
    <p>{message}</p>
  </div>
);

const FAQ = () => {
  // State Management
  const [activeIndex, setActiveIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [error, setError] = useState(null);
  const [faqImages, setFaqImages] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [loadingImages, setLoadingImages] = useState({});

  // Get token from localStorage
  const token = localStorage.getItem("accessToken");

  // Answer Parsing Logic
  const parseAnswers = (answersStr) => {
    // Handle array input
    if (Array.isArray(answersStr)) {
      return answersStr.map((answer) => String(answer));
    }

    // Handle string input
    if (typeof answersStr === "string") {
      try {
        // Try parsing as JSON if it starts with [
        if (answersStr.trim().startsWith("[")) {
          const parsed = JSON.parse(answersStr);
          return Array.isArray(parsed) ? parsed.map(String) : [answersStr];
        }

        // Handle HTML content
        if (answersStr.includes("<") || answersStr.includes(">")) {
          return [answersStr];
        }

        // Default case: return as single answer
        return [answersStr];
      } catch (err) {
        console.warn("Answer parsing failed:", err);
        return [answersStr];
      }
    }

    // Fallback for invalid input
    return [];
  };

  // Image Fetching Logic
  const fetchFaqImages = async (images, index) => {
    if (!images || images.length === 0) return;

    setLoadingImages((prev) => ({ ...prev, [index]: true }));

    try {
      const stringifiedImages = JSON.stringify(images);
      const response = await axios.post(
        `${BASE_URL}/faq/getFaqImages`,
        { images: stringifiedImages },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Process presigned URLs
      let presignedUrls = [];
      if (response.data?.presignedUrlArray) {
        presignedUrls = Array.isArray(response.data.presignedUrlArray)
          ? response.data.presignedUrlArray
          : [response.data.presignedUrlArray];
      }

      setFaqImages((prev) => ({
        ...prev,
        [index]: presignedUrls,
      }));
    } catch (err) {
      console.error("Image fetch error:", err);
      setError("Failed to load images. Please try again.");
    } finally {
      setLoadingImages((prev) => ({ ...prev, [index]: false }));
    }
  };

  // FAQ Toggle Handler
  const toggleFAQ = async (index, faq) => {
    const newActiveIndex = activeIndex === index ? null : index;
    setActiveIndex(newActiveIndex);

    // Fetch images if needed
    if (newActiveIndex !== null && faq.images && !faqImages[index]) {
      await fetchFaqImages(faq.images, index);
    }
  };

  // FAQ Item Renderer
  const renderFaqItem = (faq, index, baseIndex = 0) => {
    const actualIndex = baseIndex + index;
    const isActive = activeIndex === actualIndex;

    return (
      <div
        key={actualIndex}
        className="mb-4 bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300"
      >
        {/* FAQ Question Button */}
        <button
          onClick={() => toggleFAQ(actualIndex, faq)}
          className={`w-full text-left flex justify-between items-center py-4 px-4 font-medium text-md ${
            isActive ? "bg-blue-500 text-white" : "bg-teal-100 text-blue-900"
          } transition-all duration-300 hover:bg-blue-400 hover:text-white`}
          aria-expanded={isActive}
        >
          <span className="flex-1 pr-4">{faq.question}</span>
          <div className="flex items-center space-x-2">
            {loadingImages[actualIndex] && <LoaderSVG />}
            <span className="text-xl">{isActive ? "−" : "+"}</span>
          </div>
        </button>

        {/* FAQ Answer Content */}
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out ${
            isActive ? "max-h-96" : "max-h-0"
          }`}
        >
          <div className="px-4 py-2 text-gray-700 bg-teal-50">
            {/* Answers List */}
            <ul className="list-disc pl-5 space-y-2">
              {faq.answers.map((answer, answerIndex) => (
                <li
                  key={answerIndex}
                  className="text-gray-700"
                  dangerouslySetInnerHTML={{
                    __html: answer
                      .replace(/\\n/g, "<br/>")
                      .replace(/\\"/g, '"'),
                  }}
                />
              ))}
            </ul>

            {/* Images Section */}
            {isActive && faq.images && (
              <div className="mt-4">
                {loadingImages[actualIndex] ? (
                  <ImageLoadingSkeleton />
                ) : faqImages[actualIndex]?.length > 0 ? (
                  <div
                    className="flex gap-4 overflow-x-auto pb-2"
                    style={{
                      scrollbarWidth: "thin",
                      scrollbarColor: "#4A90E2 #D1E8FF",
                    }}
                  >
                    {faqImages[actualIndex].map((imageUrl, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={imageUrl}
                        alt={`FAQ ${actualIndex + 1} illustration ${
                          imgIndex + 1
                        }`}
                        className="h-48 w-auto object-cover rounded-lg flex-shrink-0"
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/api/placeholder/400/300";
                        }}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Initial FAQ Fetch
  useEffect(() => {
    const fetchFaqs = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/faq/getFaq`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const processedFaqs = response.data.map((faq) => ({
          ...faq,
          answers: parseAnswers(faq.answers),
        }));

        setFaqs(processedFaqs);
      } catch (err) {
        console.error("FAQ fetch error:", err);
        setError("Failed to fetch FAQs. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchFaqs();
  }, []);

  // Loading State
  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-96">
        <div className="flex flex-col items-center space-y-2">
          <LoaderSVG />
          <p className="text-blue-500">Loading FAQs...</p>
        </div>
      </div>
    );
  }

  // Main Render
  return (
    <div className="max-w-7xl mx-auto p-4">
      <h2 className="text-3xl font-bold mb-8 text-center text-blue-600">
        Student Internship FAQs
      </h2>

      {error && <ErrorMessage message={error} />}

      <div className="flex flex-col md:flex-row gap-4">
        {/* First Column */}
        <div className="w-full md:w-1/2">
          {faqs
            .slice(0, Math.ceil(faqs.length / 2))
            .map((faq, index) => renderFaqItem(faq, index))}
        </div>

        {/* Second Column */}
        <div className="w-full md:w-1/2">
          {faqs
            .slice(Math.ceil(faqs.length / 2))
            .map((faq, index) =>
              renderFaqItem(faq, index, Math.ceil(faqs.length / 2))
            )}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
