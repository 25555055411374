import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Confetti from "react-confetti";
import axios from "axios";
import { BASE_URL } from "../../services/configUrls";

const LinkedInCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState("loading"); // 'loading', 'success', 'error'
  const [showConfetti, setShowConfetti] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const shareToLinkedIn = async () => {
      try {
        // console.log("Full URL:", window.location.href);
        // console.log("Location Object:", {
        //   pathname: location.pathname,
        //   search: location.search,
        //   hash: location.hash,
        // });

        const params = new URLSearchParams(location.search);
        // console.log("All URL Parameters:");
        // params.forEach((value, key) => {
        //   console.log(`${key}:`, value);
        // });

        const code = params.get("code");

        if (!code) {
          throw new Error("No authorization code received");
        }

        // Get image data from session storage
        const imageData = sessionStorage.getItem("certificateImage");
        const certificateName = sessionStorage.getItem("certificateName");

        if (!imageData || !certificateName) {
          throw new Error("Certificate data not found");
        }

        // Convert base64 to blob
        const base64Response = await fetch(imageData);
        const blob = await base64Response.blob();

        // Create form data
        const formData = new FormData();
        formData.append("code", code.trim());
        formData.append(
          "description",
          "I just completed my internship at EduSkills Foundation!"
        );
        formData.append("certificateImage", blob, `${certificateName}.jpg`);

        // Make API call to your backend
        const response = await axios.post(
          `${BASE_URL}/documents/linkedinPost`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        if (response.status === 200) {
          // Clear stored data
          sessionStorage.removeItem("certificateImage");
          sessionStorage.removeItem("certificateName");

          setStatus("success");
          setShowConfetti(true);

          setTimeout(() => {
            navigate("/internship/certificate/long-term", { replace: true });
          }, 3500);
        } else {
          throw new Error(
            response.data.message || "Failed to share on LinkedIn"
          );
        }
      } catch (err) {
        console.error("LinkedIn sharing error:", err);
        setError(err.message || "Failed to share certificate on LinkedIn");
        setStatus("error");

        setTimeout(() => {
          navigate("/internship/certificate/long-term", { replace: true });
        }, 3500);
      }
    };

    // Add a small delay before starting the API call
    setTimeout(() => {
      shareToLinkedIn();
    }, 1500); // Show loader for at least 1.5 seconds
  }, [navigate, location]);

  const renderLoader = () => (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <div className="text-center p-8 rounded-lg max-w-xl">
        <div className="mb-8 relative">
          {/* LinkedIn-themed loader animation */}
          <svg className="w-24 h-24 mx-auto" viewBox="0 0 100 100">
            <circle
              cx="50"
              cy="50"
              r="45"
              fill="none"
              stroke="#E8E8E8"
              strokeWidth="8"
            />
            <circle
              cx="50"
              cy="50"
              r="45"
              fill="none"
              stroke="#0A66C2"
              strokeWidth="8"
              strokeLinecap="round"
              strokeDasharray="283"
              strokeDashoffset="283"
              className="animate-[spin_1.5s_ease-in-out_infinite]"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-semibold text-gray-700 mb-3">
          Processing Your Request
        </h2>
        <p className="text-gray-500 animate-pulse">
          Please wait while we connect with LinkedIn...
        </p>
      </div>
    </div>
  );

  const renderSuccess = () => (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-50 to-white">
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={200}
          gravity={0.3}
        />
      )}

      <div className="text-center p-8 rounded-lg max-w-xl">
        <div className="mb-6">
          <svg className="w-24 h-24 mx-auto mb-4" viewBox="0 0 100 100">
            <circle
              className="animate-[fill_0.5s_ease-in-out_forwards]"
              cx="50"
              cy="50"
              r="45"
              fill="none"
              stroke="#0A66C2"
              strokeWidth="8"
              strokeDasharray="283"
              strokeDashoffset="283"
            />
            <path
              className="animate-[draw_0.5s_ease-in-out_0.5s_forwards]"
              d="M30 50 L45 65 L70 35"
              fill="none"
              stroke="#0A66C2"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="100"
              strokeDashoffset="100"
            />
          </svg>
        </div>

        <h2 className="text-3xl font-bold mb-4 text-gray-800 animate-fade-in">
          Great News! 🎉
        </h2>
        <p className="text-xl text-gray-600 mb-6 animate-fade-in">
          Your certificate is being shared on LinkedIn...
        </p>
        <p className="text-sm text-gray-500 animate-fade-in animate-pulse">
          Redirecting you back to continue your journey
        </p>
      </div>
    </div>
  );

  const renderError = () => (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-red-50 to-white">
      <div className="text-center p-8 rounded-lg max-w-xl">
        <div className="mb-6">
          <svg
            className="w-24 h-24 mx-auto mb-4 animate-shake"
            viewBox="0 0 100 100"
          >
            <circle
              className="animate-[fill_0.5s_ease-in-out_forwards]"
              cx="50"
              cy="50"
              r="45"
              fill="none"
              stroke="#EF4444"
              strokeWidth="8"
              strokeDasharray="283"
              strokeDashoffset="283"
            />
            <path
              className="animate-[draw_0.5s_ease-in-out_0.5s_forwards]"
              d="M35 35 L65 65 M65 35 L35 65"
              fill="none"
              stroke="#EF4444"
              strokeWidth="8"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="100"
              strokeDashoffset="100"
            />
          </svg>
        </div>

        <h2 className="text-3xl font-bold mb-4 text-red-500 animate-fade-in">
          Oops! Something went wrong 😕
        </h2>
        <p className="text-xl text-gray-600 mb-6 animate-fade-in">{error}</p>
        <div className="animate-fade-in">
          <p className="text-gray-500 mb-2">Don't worry, you can try again!</p>
          <p className="text-sm text-gray-400 animate-pulse">
            Redirecting you back...
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {status === "loading" && renderLoader()}
      {status === "success" && renderSuccess()}
      {status === "error" && renderError()}

      <style jsx>{`
        @keyframes fill {
          from {
            stroke-dashoffset: 283;
          }
          to {
            stroke-dashoffset: 0;
          }
        }

        @keyframes draw {
          from {
            stroke-dashoffset: 100;
          }
          to {
            stroke-dashoffset: 0;
          }
        }

        @keyframes fade-in {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes shake {
          0%,
          100% {
            transform: translateX(0);
          }
          15%,
          45%,
          75% {
            transform: translateX(-10px);
          }
          30%,
          60%,
          90% {
            transform: translateX(10px);
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
            stroke-dashoffset: 283;
          }
          50% {
            stroke-dashoffset: 70;
          }
          100% {
            transform: rotate(360deg);
            stroke-dashoffset: 283;
          }
        }
      `}</style>
    </>
  );
};

export default LinkedInCallback;
