import * as yup from "yup";
import { indianStates } from "../indianStates";

export const personalDetailsSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name is required")
    .min(2, "First Name must be at least 2 characters"),
  lastName: yup
    .string()
    .required("Last Name is required")
    .min(2, "Last Name must be at least 2 characters"),
  middleName: yup.string(),
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email format"),
  contactNo: yup
    .string()
    .required("Contact Number is required")
    .matches(/^\d{10}$/, "Contact Number must be 10 digits"),
  whatsappNo: yup
    .string()
    .required("WhatsApp Number is required")
    .matches(/^\d{10}$/, "WhatsApp Number must be 10 digits"),
  aicteInternshipId: yup.string().required("AICTE Internship ID is required"),
  apaarId: yup.string(),
  dateOfBirth: yup
    .date()
    .required("Date of Birth is required")
    .max(new Date(), "Date of Birth cannot be in the future"),
  gender: yup.string().required("Gender is required"),
  category: yup.string().required("Category is required"),
});

export const addressSchema = yup.object().shape({
  addressLine1: yup
    .string()
    .required("Address Line 1 is required")
    .min(5, "Address should be at least 5 characters"),
  addressLine2: yup.string(),
  city: yup
    .string()
    .required("City is required")
    .matches(/^[a-zA-Z\s]*$/, "City should only contain letters"),
  state: yup
    .string()
    .required("State is required")
    .oneOf(indianStates, "Please select a valid state"),
  pinCode: yup
    .string()
    .required("PIN Code is required")
    .matches(/^[1-9][0-9]{5}$/, "Please enter a valid 6-digit PIN code"),
  landmark: yup.string(),
});
