import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Paper,
  Tooltip,
  Zoom,
  TextField,
  InputAdornment,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import SearchIcon from "@mui/icons-material/Search";
import { BASE_URL } from "../../services/configUrls";
import { toast } from "react-toastify";

const DocumentModal = ({ domainId, isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [imageScale, setImageScale] = useState(1);
  const [imageError, setImageError] = useState(false);
  const [pageInput, setPageInput] = useState("");

  const fetchDocument = async (sequenceNumber) => {
    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}/documents/get_documents`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({
          domainId: domainId,
          sequenceNumber: sequenceNumber,
        }),
      });

      if (!response.ok) {
        let errorMessage = "An error occurred";
        try {
          const errorData = await response.json();
          errorMessage = errorData.detail || errorMessage;
        } catch (e) {
          console.error("Error parsing error response:", e);
        }

        if (response.status === 401) {
          toast.error("Unauthorized access. Please log in again.");
        } else if (response.status === 403) {
          toast.error("Access forbidden. Contact support.");
        } else {
          toast.error(errorMessage);
        }
        throw new Error(errorMessage);
      }

      const data = await response.json();
      setDocuments((prev) => [...prev, data.processDocument]);
      setTotalPages(data.documentSize);
    } catch (error) {
      console.error("Error fetching document:", error);
      toast.error("Failed to fetch document");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && domainId) {
      setCurrentPage(1);
      setDocuments([]);
      setImageScale(1);
      setImageError(false);
      setImageLoading(true);
      setPageInput("");
      fetchDocument(1);
    }
  }, [isOpen, domainId]);

  useEffect(() => {
    if (
      isOpen &&
      !documents.find((doc) => doc.sequence_number === currentPage)
    ) {
      fetchDocument(currentPage);
    }
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
      setImageScale(1);
      setImageError(false);
      setImageLoading(true);
      setPageInput("");
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      setImageScale(1);
      setImageError(false);
      setImageLoading(true);
      setPageInput("");
    }
  };

  const handlePageSearch = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(pageInput);
    if (pageNumber && pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setImageScale(1);
      setImageError(false);
      setImageLoading(true);
    } else {
      toast.error(
        `Please enter a valid page number between 1 and ${totalPages}`
      );
    }
  };

  const handlePageInputChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      setPageInput(value);
    }
  };

  const handleZoomIn = () => {
    setImageScale((prev) => Math.min(prev + 0.2, 3));
  };

  const handleZoomOut = () => {
    setImageScale((prev) => Math.max(prev - 0.2, 0.5));
  };

  const getCurrentDocument = () => {
    return documents.find((doc) => doc.sequence_number === currentPage);
  };

  const isValidImageUrl = (url) => {
    if (!url) return false;

    try {
      new URL(url);
      if (url.includes("s3.amazonaws.com")) {
        return true;
      }
      const validExtensions = [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".bmp",
        ".webp",
      ];
      return validExtensions.some((ext) => url.toLowerCase().endsWith(ext));
    } catch (e) {
      return false;
    }
  };

  const createMarkup = (htmlContent) => {
    return {
      __html: htmlContent,
    };
  };

  const htmlStyles = {
    "& img": {
      maxWidth: "100%",
      height: "auto",
    },
    "& table": {
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: "1rem",
    },
    "& th, & td": {
      border: "1px solid #ddd",
      padding: "8px",
      textAlign: "left",
    },
    "& a": {
      color: "#1976d2",
      textDecoration: "none",
    },
    "& a:hover": {
      textDecoration: "underline",
    },
    "& p": {
      margin: "0.5rem 0",
    },
  };

  const shouldShowImage = (doc) => {
    return doc.image && isValidImageUrl(doc.image) && !imageError;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          minHeight: "60vh",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#1976d2",
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Course Access Document</Typography>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <Box sx={{ mb: 2, mt: 3 }}>
          <form onSubmit={handlePageSearch}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Enter page number"
              value={pageInput}
              onChange={handlePageInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      disabled={!pageInput || loading}
                    >
                      Go to Page
                    </Button>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                  "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1976d2",
                    },
                  },
                },
              }}
            />
          </form>
        </Box>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "300px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>
            {getCurrentDocument() ? (
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  backgroundColor: "#ffffff",
                  transition: "all 0.3s ease-in-out",
                  "&:hover": {
                    boxShadow: 6,
                  },
                }}
              >
                {getCurrentDocument().document && (
                  <Box
                    sx={{
                      mb: 3,
                      "& .document-content": htmlStyles,
                    }}
                  >
                    <div
                      className="document-content"
                      dangerouslySetInnerHTML={createMarkup(
                        getCurrentDocument().document
                      )}
                    />
                  </Box>
                )}

                {shouldShowImage(getCurrentDocument()) && (
                  <Box sx={{ mt: 3 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          alignItems: "center",
                          justifyContent: "center",
                          mb: 2,
                        }}
                      >
                        <Tooltip title="Zoom Out">
                          <IconButton
                            onClick={handleZoomOut}
                            disabled={imageScale <= 0.5}
                            color="primary"
                          >
                            <ZoomOutIcon />
                          </IconButton>
                        </Tooltip>
                        <Typography variant="body2" color="textSecondary">
                          {Math.round(imageScale * 100)}%
                        </Typography>
                        <Tooltip title="Zoom In">
                          <IconButton
                            onClick={handleZoomIn}
                            disabled={imageScale >= 3}
                            color="primary"
                          >
                            <ZoomInIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box
                        sx={{
                          overflow: "auto",
                          maxHeight: "50vh",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        {imageLoading && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              zIndex: 1,
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                height: "400px",
                                bgcolor: "#f5f5f5",
                                animation: "pulse 1.5s ease-in-out infinite",
                                "@keyframes pulse": {
                                  "0%": {
                                    opacity: 0.6,
                                  },
                                  "50%": {
                                    opacity: 0.8,
                                  },
                                  "100%": {
                                    opacity: 0.6,
                                  },
                                },
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          </Box>
                        )}
                        <Zoom in={true}>
                          <img
                            src={getCurrentDocument().image}
                            alt="Document"
                            crossOrigin="anonymous"
                            onError={() => setImageError(true)}
                            onLoad={() => setImageLoading(false)}
                            style={{
                              transform: `scale(${imageScale})`,
                              transformOrigin: "center",
                              transition: "transform 0.3s ease-in-out",
                              maxWidth: "100%",
                              height: "auto",
                              visibility: imageLoading ? "hidden" : "visible",
                            }}
                          />
                        </Zoom>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Paper>
            ) : (
              <Typography variant="body1" color="textSecondary" align="center">
                No document found
              </Typography>
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          borderTop: "1px solid #e0e0e0",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box>
            <Button
              onClick={handlePrevPage}
              disabled={currentPage <= 1 || loading}
              startIcon={<NavigateBeforeIcon />}
              variant="outlined"
              sx={{
                mr: 1,
                "&:hover": {
                  backgroundColor: "#1976d2",
                  color: "white",
                },
              }}
            >
              Previous
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage >= totalPages || loading}
              endIcon={<NavigateNextIcon />}
              variant="outlined"
              sx={{
                "&:hover": {
                  backgroundColor: "#1976d2",
                  color: "white",
                },
              }}
            >
              Next
            </Button>
          </Box>

          <Typography
            variant="body2"
            sx={{
              color: "#1976d2",
              fontWeight: "medium",
              backgroundColor: "rgba(25, 118, 210, 0.1)",
              padding: "4px 12px",
              borderRadius: "16px",
            }}
          >
            Page {currentPage} of {totalPages}
          </Typography>

          <Button
            onClick={onClose}
            variant="contained"
            color="error"
            sx={{
              "&:hover": {
                backgroundColor: "#d32f2f",
                transform: "scale(1.05)",
              },
            }}
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentModal;
