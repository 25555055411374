import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import OngoingTable from "./OngoingTable";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FormTable from "./FormTable";
import {
  getInternshipCourseDetails,
  getOngoingInternships,
  profileGetDataService,
} from "../../services/dataService";
import { Hourglass } from "react-loader-spinner"; // Example loader component
import DomainChoose from "../Domains Choose/DomainChoose";
import AvailableDomain from "../Domains Choose/AvailableDomain";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../services/configUrls";
import CopyDomainChoose from "../Domains Choose/CopyDomainChoose";
import { useNavigate } from "react-router-dom";
import { red } from "@mui/material/colors";
import OngoingTableOld from "./OngoingTableCopy";

/**
 * SearchableTable component renders a table with search and filter functionalities.
 * It fetches domain and internship data, and conditionally renders content based on the fetched data.
 *
 * @component
 * @param {Object[]} data - The data to be displayed in the table.
 * @param {string[]} columns - The columns to be displayed in the table.
 *
 * @example
 * const data = [
 *   { id: 1, name: 'John Doe', status: 'Completed' },
 *   { id: 2, name: 'Jane Doe', status: 'Enrolled' }
 * ];
 * const columns = ['id', 'name', 'status'];
 * <SearchableTable data={data} columns={columns} />
 *
 * @returns {JSX.Element} The rendered component.
 */
const SearchableTable = ({ data, columns }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCriteria, setFilterCriteria] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true); // Loading state for data
  const [dataReady, setDataReady] = useState(false); // New state for conditional rendering
  const itemsPerPage = 5;
  const [domainId, setDomainId] = React.useState(0);
  const [submitStatus, setSubmitStatus] = React.useState(false);
  const [processDocument, setProcessDocument] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [internship, setInternship] = useState([]);
  const [domains, setDomains] = useState([]);
  const [status, setStatus] = useState(0);
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [isLongTermApplied, setIsLongTermApplied] = useState(0); // Assuming you get this from API response
  const [showEligibilityDialog, setShowEligibilityDialog] = useState(false);

  const [profileUpdateStatus, setProfileUpdateStatus] = useState(1);

  useEffect(() => {
    getInternshipCourseDetails
      .coursesDetails()
      .then((response) => {
        // Check if response is an array or an object
        if (Array.isArray(response.data)) {
          // Handle if it's an array and not empty
          if (response.data.length > 0) {
            const { is_eligible_for_longterm } = response.data[0]; // Assuming eligibility info is in the first element
            setIsLongTermApplied(is_eligible_for_longterm);
          } else {
            // If data is an empty array, avoid showing the modal
            setIsLongTermApplied(null);
          }
        } else if (
          typeof response.data === "object" &&
          response.data !== null
        ) {
          // Handle if it's an object and has the required properties
          const { is_eligible_for_longterm } = response.data;
          if (is_eligible_for_longterm !== undefined) {
            setIsLongTermApplied(is_eligible_for_longterm);
          } else {
            // If the object doesn't contain eligibility information, avoid showing the modal
            setIsLongTermApplied(null);
          }
        }
      })
      .catch((error) => {
        console.log("An error occurred while fetching course details:", error);
        setIsLongTermApplied(null); // Set to null in case of an error
      });
  }, []);

  useEffect(() => {
    // Show dialog based on eligibility when the user reaches the page
    if (
      isLongTermApplied !== null &&
      (isLongTermApplied === 0 || isLongTermApplied === 1)
    ) {
      setShowEligibilityDialog(true);
    } else {
      setShowEligibilityDialog(false);
    }
  }, [isLongTermApplied]);

  const handleCloseEligibilityDialog = () => {
    setShowEligibilityDialog(false);
  };

  // const handleCloseEligibilityDialog = () => {
  //   setShowEligibilityDialog(false);
  // };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await profileGetDataService.profileData(); // Fetch the data from your service
        const data = response?.data;

        if (data?.profile_update_status === 0) {
          navigate("/profile");
          window.location.reload();
          return; // Don't continue with setting the profile form data
        }

        setProfileUpdateStatus(data?.profile_update_status);
      } catch (error) {
        console.error("Failed to fetch profile data:", error);
      }
    };

    fetchProfileData();
  }, []);

  if (profileUpdateStatus === 0) {
    navigate("/profile");
  }

  useEffect(() => {
    // Simulate data loading
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/domain/domainChoose`, {
          headers: {
            Authorization: `Bearer ${token}`, // Including the token in Authorization header
          },
        });

        // console.log(response);
        setDomains(response.data[0]); // Assuming response.data is the array of domains
        // setStatus(response.data[0]); // Assuming response.data[0] contains the status
      } catch (error) {
        console.error("Error fetching domains:", error);
        // toast.error("Failed to load domains", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      } finally {
        // setLoadingDomains(false);
      }
    };

    fetchDomains();
  }, [token]);

  useEffect(() => {
    const fetchOngoingInternshipData = async () => {
      try {
        const response = await getOngoingInternships.ongoingInternshipsData();
        if (response.data && response.data.internships) {
          const data = response.data.internships;
          setInternship(data);
          setDomainId(data[0].domain_id);
          setSubmitStatus(data[0].submit_status);
          setProcessDocument(data[0].process_document);
          setPercentage(data[0].percent);
        } else {
          throw new Error("Invalid response structure");
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setDataReady(true); // Data is now ready for conditional rendering
      }
    };
    fetchOngoingInternshipData();
  }, []);

  const filteredData = data
    .filter((item) =>
      Object.values(item).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    )
    .filter((item) =>
      filterCriteria === "all" ? true : item.status === filterCriteria
    );

  const handleFilterChange = (newFilter) => {
    setFilterCriteria(newFilter);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (
      pageNumber >= 1 &&
      pageNumber <= Math.ceil(filteredData.length / itemsPerPage)
    ) {
      setCurrentPage(pageNumber);
    }
  };

  // Conditionally render based on the dataReady state
  if (!dataReady) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Hourglass type="ThreeDots" color="#00BFFF" height={60} width={60} />
      </div>
    );
  }

  // if (domainId === 32 && submitStatus === false) {
  //   return <FormTable />;
  // }

  // if (submitStatus === true && domainId === 32) {
  //   return (
  //     <div className="flex flex-col items-center justify-center h-screen text-center bg-gray-100">
  //       <div className="bg-white p-8 rounded-lg shadow-lg max-w-md flex flex-col items-center">
  //         <Icon
  //           icon="mdi:check-circle"
  //           width={64}
  //           height={64}
  //           className="text-green-500 mb-4"
  //         />
  //         <h1 className="text-3xl font-semibold text-gray-800">
  //           Form Submitted Successfully!
  //         </h1>
  //         <p className="mt-4 text-sm text-gray-600">
  //           You have already submitted your genAI public request url
  //           successfully. Thank you for your submission.
  //         </p>
  //         <button
  //           //onClick={() => (window.location.href = "/home")}
  //           className="mt-6 px-6 py-3 bg-blue-500 text-white rounded-full shadow hover:bg-blue-600 transition-all duration-200"
  //         >
  //           You Can Logout Now
  //         </button>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div>
      <div className="w-full overflow-x-auto shadow-lg border p-4 rounded-md">
        <div className="md:flex justify-between mb-4">
          <div className="mb-4 md:mb-0 md:mr-4">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="p-2 rounded-md w-full md:w-60 shadow-sm outline-sky-300 border-2 border-gray-300 text-gray-900"
            />
          </div>
          <div className="text-gray-900">
            <label className="mr-2 md:mt-0">Filter by:</label>
            <select
              value={filterCriteria}
              onChange={(e) => handleFilterChange(e.target.value)}
              className="border-2 border-gray-300 p-2 rounded-md"
            >
              <option value="all">All</option>
              <option value="Apply">Apply</option>
              <option value="Enrolled">Enrolled</option>
              <option value="Completed">Completed</option>
            </select>
          </div>
        </div>
        <div className="table-container">
          {isLoading ? (
            <SkeletonTable columns={columns} itemsPerPage={itemsPerPage} />
          ) : (
            <table className="w-full mb-5">
              <thead className="text-base bg-gray-800 text-gray-100 shadow-sm rounded-md">
                <tr className="shadow-sm">
                  {columns.map((column) => (
                    <th
                      key={column}
                      className={`py-2 px-4 text-base ${
                        column === "title" ? "text-left pl-20" : "text-center"
                      } border-b`}
                    >
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              {filteredData.length === 0 ? (
                <tbody>
                  <tr>
                    <td
                      colSpan={columns.length}
                      className="text-center text-gray-500 pt-10"
                    >
                      No records found.
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody className="table-body">
                  {currentItems.map((item, index) => (
                    <tr
                      key={item.sl_no}
                      className={`border-b text-center text-base ${
                        index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                      }`}
                    >
                      {columns.map((column) => (
                        <td
                          key={column}
                          className={`py-2 px-4 text-base ${
                            column === "Domain"
                              ? "text-left pl-20"
                              : "text-center"
                          } border-b`}
                        >
                          {column === "status" ? (
                            item["status"] === "Completed" ? (
                              <span className="bg-green-100 text-green-800 py-1 px-3 rounded-full">
                                Completed
                              </span>
                            ) : item["status"] === "Failed" ? (
                              <span className="bg-blue-100 text-blue-800 py-1 px-3 rounded-full">
                                Fail
                              </span>
                            ) : (
                              <span className="bg-yellow-100 text-yellow-800 py-1 px-3 rounded-full">
                                Enrolled
                              </span>
                            )
                          ) : column === "Action" ? (
                            item["Status"] === "Completed" ? (
                              <a
                                href="https://aicte-internship.eduskillsfoundation.org/pages/home/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-flex items-center justify-center px-4 py-2 text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 rounded-md"
                              >
                                {/* <Icon
                                  icon="fluent:document-save-20-filled"
                                  height={22}
                                  width={22}
                                  className="ml-12 cursor-pointer text-green-400"
                                /> */}
                                Download Certificate
                              </a>
                            ) : item["status"] === "Failed" ? (
                              <Icon
                                icon="codicon:git-stash-apply"
                                width={22}
                                height={22}
                                className="ml-12 cursor-pointer"
                              />
                            ) : (
                              <span className="bg-red-700 text-lg text-white py-1 px-5 rounded-full">
                                Fail
                              </span>
                            )
                          ) : (
                            item[column]
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          )}
        </div>
        {!isLoading && (
          <div className="flex justify-end mt-6">
            <button
              className={`mx-2 px-3 py-1 ${
                currentPage === 1
                  ? "bg-gray-300 text-gray-600"
                  : "bg-blue-500 text-white"
              } rounded-md shadow-md`}
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="mx-2 text-base">
              Page {currentPage} of{" "}
              {Math.ceil(filteredData.length / itemsPerPage)}
            </span>
            <button
              className={`mx-2 px-3 py-1 ${
                indexOfLastItem >= filteredData.length
                  ? "bg-gray-300 text-gray-600"
                  : "bg-blue-500 text-white"
              } rounded-md shadow-md`}
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastItem >= filteredData.length}
            >
              Next
            </button>
          </div>
        )}
        {/* <Divider sx={{ marginTop: "60px" }} /> */}
        {/* {(percentage !== 100 && internship.length !== 0) && <OngoingTable />} */}
        {/* {domains.length === 0 && status === "1" && (
          <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg p-6 text-center">
              <h1 className="text-2xl font-bold text-gray-800 mb-4">
                Successfully applied for the AICTE - EduSkills Virtual
                Internship.
              </h1>
              <p className="text-gray-600 text-xl mb-4">
                Application has been sent to your institute for approval.
              </p>
            </div>
          </div>
        )} */}

        {/* <OngoingTable /> */}

        {/* <div className="flex items-center justify-center mt-8 px-8 py-8 bg-gray-100">
          <div className="max-w-sm w-full bg-white shadow-lg rounded-lg p-6 text-center">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">
              Cohort-10 Will Open Soon!
            </h1>
            <p className="text-gray-600 mb-4">
              Stay tuned for updates and be ready to join us!
            </p>
          </div>
        </div> */}
      </div>

      <Dialog
        open={showEligibilityDialog}
        onClose={handleCloseEligibilityDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Apsche Certificate Application Eligibility</DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontSize: "1rem", // Increase the font size
              color: "black", // Set the color to Material UI's red
              fontWeight: "bold", // Set the font
            }}
          >
            {isLongTermApplied === 1 &&
              "You are eligible to apply for both long-term and short-term APSCHE certificates. Please proceed to the certificate menu to apply for either or both. If you have already applied, please ignore this message."}
            {isLongTermApplied === 0 &&
              "You are eligible to apply for a Short-Term APSCHE certificate. Please visit the certificate menu to proceed with your application. If you have already applied, kindly ignore this message."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseEligibilityDialog}
            variant="contained"
            color="info"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {domains === "4" ? (
        <OngoingTableOld />
      ) : (
        <Box padding={2} width="100%">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {/* <DomainChoose /> */}
              <CopyDomainChoose />
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

const SkeletonTable = ({ columns, itemsPerPage }) => (
  <table className="w-full mb-5">
    <thead className="text-base bg-gray-800 text-gray-100 shadow-sm rounded-md">
      <tr className="shadow-sm">
        {columns.map((column) => (
          <th
            key={column}
            className={`py-2 px-4 text-base ${
              column === "title" ? "text-left pl-20" : "text-center"
            } border-b`}
          >
            {column}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {[...Array(itemsPerPage)].map((_, index) => (
        <tr
          key={index}
          className={`border-b text-center text-base ${
            index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
          }`}
        >
          {columns.map((column) => (
            <td
              key={column}
              className={`py-2 px-4 text-base ${
                column === "title" ? "text-left pl-20" : "text-center"
              } border-b`}
            >
              <Skeleton />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export default SearchableTable;
