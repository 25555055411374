import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Timer } from "lucide-react";

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date("2025-01-19");

    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({ days, hours, minutes, seconds });
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, []);

  const percentage = ((timeLeft.days / 365) * 100).toFixed(0);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        p: 2,
        borderRadius: 2,
        bgcolor: blue[50],
        border: `1px solid ${blue[100]}`,
        maxWidth: "fit-content",
      }}
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          value={percentage}
          size={60}
          thickness={4}
          sx={{ color: blue[500] }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Timer size={24} color={blue[700]} />
        </Box>
      </Box>

      <Box>
        <Typography
          variant="subtitle2"
          color="error"
          fontWeight="bold"
          gutterBottom
          sx={{
            fontSize: "0.75rem",
            textTransform: "uppercase",
            letterSpacing: "0.5px",
            animation: "blink 2s infinite",
          }}
        >
          Course Will Close Within
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" color={blue[900]} fontWeight="bold">
              {timeLeft.days}
            </Typography>
            <Typography variant="caption" color={blue[700]}>
              Days
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" color={blue[900]} fontWeight="bold">
              {timeLeft.hours}
            </Typography>
            <Typography variant="caption" color={blue[700]}>
              Hours
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" color={blue[900]} fontWeight="bold">
              {timeLeft.minutes}
            </Typography>
            <Typography variant="caption" color={blue[700]}>
              Min
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" color={blue[900]} fontWeight="bold">
              {timeLeft.seconds}
            </Typography>
            <Typography variant="caption" color={blue[700]}>
              Sec
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CountdownTimer;
