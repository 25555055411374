import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
  Link,
  Box,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InfoIcon from "@mui/icons-material/Info";
import { blue, green } from "@mui/material/colors";
import { getOngoingInternships } from "../../services/dataService";
import { Navigate, Link as RouterLink, useNavigate } from "react-router-dom";
// import PendingIcon from "@mui/icons-material/MoreHoriz";
// import InProgressIcon from "@mui/icons-material/DonutLarge";
// import CompleteIcon from "@mui/icons-material/CheckCircle";
//import { FiMoreHorizontal, FiUpload } from "react-icons/fi";
//import { FiLoader } from "react-icons/fi";
//import { FiCheckCircle } from "react-icons/fi";
import { MdAssessment, MdOutlinePending, MdPending } from "react-icons/md";
import { FaCircleCheck, FaCirclePause } from "react-icons/fa6";
import { Document, Page } from "react-pdf";
import PdfViewer from "./PDFViewer";
import googlepdf from "../../assets/Google AI-ML course process document.pdf";
import QuizIcon from "@mui/icons-material/Quiz";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LockIcon from "@mui/icons-material/Lock";
import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import pdf from "../../assets/Google AI-ML course process document.pdf";

import { pdfjs } from "react-pdf";
import { BASE_URL } from "../../services/configUrls";
import { toast } from "react-toastify";
import { Calendar, Clock } from "lucide-react";
import CountdownTimer from "./TimerComponent";
import DocumentModal from "./DocumentModal";
import AnnouncementsSection from "./AnnouncementsSection";
import { DocumentAccessCell } from "./AccessLinkCell";
import CourseAccessLinkCell from "./CourseAccessLinkCell";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const importantDates = [
  // {
  //   label: "Cohort-10 Certificate Upload Last Date:",
  //   date: "30th December 2024",
  // },
  // {
  //   label: "The Course Access Document will open on:",
  //   date: "9th January 2025",
  // },
  { label: "Cohort-11 Registration Close:", date: "31st January 2025" },
  {
    label: "Cohort-11 Cert Upload Start:",
    date: "1st February 2025",
  },

  { label: "Final Certificate Issue Start :", date: "01 March 2025" },
  //   { label: "Certificate Download End Date:", date: "31 March 2024" },
];

const OngoingTableOld = () => {
  const navigate = useNavigate();
  //const [searchText, setSearchText] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [fetchedRows, setFetchedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState([]);
  const [domainHeader, setDomainHeader] = useState("");
  const [active, setActive] = useState([]);
  const [isData, setIsData] = useState([]);
  const [showCertUpload, setShowCertUpload] = useState(false);

  const [pdfUrl, setPdfUrl] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [numPages, setNumPages] = useState(null); // Track number of pages
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0); // Track the zoom level (scale)
  const [openRecordingDialog, setOpenRecordingDialog] = useState(false);
  const [selectedRecordings, setSelectedRecordings] = useState([]);
  const [isAfterTargetDate, setIsAfterTargetDate] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [cohort, setCohort] = useState([]);
  const [loadingAccessLink, setLoadingAccessLink] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [domainDetails, setDomainDetails] = useState(null);

  const targetDate = new Date("2024-10-20");

  useEffect(() => {
    const currentDate = new Date();
    // Check if the current date is after the target date
    setIsAfterTargetDate(currentDate > targetDate);
  }, []);

  const handleInfoDialogOpen = () => {
    setOpenInfoDialog(true);
  };

  const handleInfoDialogClose = () => {
    setOpenInfoDialog(false);
  };

  const handlePdfOpen = (documentLink) => {
    // Check if the document link is a TinyURL (or any other short URL)
    const isUrl =
      documentLink.startsWith("http") || documentLink.startsWith("https");
    const isBase64 = documentLink.startsWith("data:application/pdf;base64,");

    if (isUrl) {
      // If it's a URL, open it in a new tab
      window.open(documentLink, "_blank");
    } else if (isBase64) {
      // If it's base64, display it in the modal
      setPdfUrl(documentLink);
      setIsDialogOpen(true);
    } else {
      // Assume it's a base64 string that needs a prefix
      const pdfBase64 = `data:application/pdf;base64,${documentLink}`;
      setPdfUrl(pdfBase64);
      setIsDialogOpen(true);
    }
  };

  const handlePdfClose = () => {
    setIsDialogOpen(false);
    setPdfUrl(null);
    setPageNumber(1);
    setScale(1.0); // Reset zoom level
  };

  const handleRecordingDialogOpen = (recordingString) => {
    // Extract all <a> tags using a regex and create an array of objects with `href` and `text` for each link
    const parser = new DOMParser();
    const doc = parser.parseFromString(recordingString, "text/html");
    const anchors = Array.from(doc.querySelectorAll("a"));

    const recordingsArray = anchors.map((anchor) => ({
      href: anchor.href, // Extract href attribute
      text: anchor.textContent, // Extract the text inside the <a> tag
    }));

    setSelectedRecordings(recordingsArray);
    setOpenRecordingDialog(true);
  };

  // Function to close the dialog
  const handleRecordingDialogClose = () => {
    setOpenRecordingDialog(false);
    setSelectedRecordings([]);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages); // Set the number of pages
  };

  // Handle next and previous page navigation
  const goToNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const onLinkClick = ({ link }) => {
    // Open links in a new tab if they are valid URLs
    if (link && link.startsWith("http")) {
      window.open(link, "_blank");
    }
  };

  const zoomIn = () => setScale(scale + 0.1); // Increase the scale
  const zoomOut = () => setScale(scale > 0.5 ? scale - 0.1 : scale); // Decrease the scale, but not below 0.5

  const fetchDomainDetails = async (cohortId, domainId) => {
    try {
      const formData = new FormData();
      formData.append("cohortId", cohortId);
      formData.append("domainId", domainId);

      const response = await fetch(
        `${BASE_URL}/internship/cohortDomainDetails`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch domain details");
      }

      const data = await response.json();
      setDomainDetails(data.domainDetails);
    } catch (error) {
      console.error("Error fetching domain details:", error);
    }
  };

  useEffect(() => {
    const fetchOngoingInternshipData = async () => {
      try {
        setLoading(true);
        const response = await getOngoingInternships.ongoingInternshipsData();

        if (response.data && response.data.internships) {
          const data = response.data.internships;
          setIsData(data);
          setSelectedCertificate(data[0].certificate_status || []);
          setActive(data[0].assessment_active);

          const rowsWithId = data.map((row, index) => ({
            ...row,
            id: row.sl_no || index,
          }));

          setFetchedRows(rowsWithId);
          setFilteredRows(rowsWithId);
          setDomainHeader(data[0].domain);
          setCohort(data[0].cohort);

          // After getting the initial data, fetch domain details
          if (data[0]) {
            await fetchDomainDetails(data[0].cohort_id, data[0].domain_id);
          }
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOngoingInternshipData();
  }, []);

  useEffect(() => {
    const checkCertUploadAccess = () => {
      if (fetchedRows.length === 0) return;

      const currentDate = new Date();
      const febFirst2025 = new Date("2025-02-01");

      // Both conditions must be true to show the upload option
      const isNotCohort11 = fetchedRows[0]?.cohort_id !== 11;
      const isAfterFebFirst = currentDate >= febFirst2025;

      setShowCertUpload(isNotCohort11 || isAfterFebFirst);
    };

    checkCertUploadAccess();
  }, [fetchedRows]);

  //   const handleSearch = (searchValue) => {
  //     setSearchText(searchValue);
  //     if (searchValue === "") {
  //       setFilteredRows(fetchedRows);
  //     } else {
  //       setFilteredRows(
  //         fetchedRows.filter((row) =>
  //           Object.keys(row).some((field) =>
  //             row[field]
  //               .toString()
  //               .toLowerCase()
  //               .includes(searchValue.toLowerCase())
  //           )
  //         )
  //       );
  //     }
  //   };

  //   const clearSearch = () => {
  //     setSearchText("");
  //     setFilteredRows(fetchedRows);
  //   };

  const statusIcons = {
    "Not Uploaded": (
      <MdPending style={{ color: blue[500], fontSize: "26px" }} />
    ),
    Uploaded: <FaCirclePause style={{ color: "orange", fontSize: "24px" }} />,
    Verified: <FaCircleCheck style={{ color: "green", fontSize: "24px" }} />,
  };

  const handleDialogOpen = (certificateNumber, certificateStatus) => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const getAccessToken = async () => {
    try {
      const response = await fetch(`${BASE_URL}/wadhwani/wadhwaniCourse`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (!response.ok) {
        // Parse JSON response for error cases
        let errorData = null;
        try {
          errorData = await response.json();
        } catch (e) {
          console.error("Error parsing JSON from response:", e);
        }

        if (response.status === 401) {
          toast.error("Unauthorized access. Please log in again.");
        } else if (response.status === 403) {
          toast.error("Access forbidden. Contact support.");
        } else if (response.status === 404) {
          toast.error("Resource not found.");
        } else if (response.status === 400) {
          toast.warning(errorData?.detail || "Bad Request.");
        } else if (response.status >= 500) {
          toast.error(
            errorData?.detail || "Server error. Please try again later."
          );
        } else {
          toast.error(errorData?.detail || "An unexpected error occurred.");
        }
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      toast.success(data.detail); // Show success toast if response is OK
      return data;
    } catch (error) {
      console.error("Error:", error);
      // toast.error("An error occurred. Please try again.");
      return null;
    }
  };

  const handleButtonClick = async () => {
    setLoadingAccessLink(true); // Show loader when button is clicked
    const url = await getAccessToken();
    setLoadingAccessLink(false); // Hide loader after API call
    if (url) {
      window.location.href = url;
    } else {
      console.error("Failed to retrieve access token.");
    }
  };

  if (loading) {
    return (
      <Box sx={{ width: "100%", marginTop: 3 }}>
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
      </Box>
    );
  }

  const getButtonProps = (assessment_active) => {
    switch (assessment_active) {
      case null:
        return {
          color: "success", // Gray
          text: " Assessment Not Applicable",
          disabled: true,
          icon: <CancelIcon />, // Cross icon
          sx: {
            fontSize: "12px",
          },
          // sx: {
          //   backgroundColor: "red",
          //   color: "white",
          //   "&.Mui-disabled": {
          //     backgroundColor: "red",
          //     color: "white",
          //   },
          //   "&:hover": {
          //     backgroundColor: "darkred",
          //   },
          // }, // Default styles
        };
      case "0":
        return {
          color: "success", // Default color
          text: "Start Assessment",
          disabled: false,
          icon: <QuizIcon />,
          sx: {}, // Default styles
        };
      case "1":
        return {
          color: "success", // Green
          text: "Assessment Completed",
          disabled: false,
          icon: <CheckCircleIcon />,
          sx: {}, // Default styles
        };
      case "4":
        return {
          color: "primary", // Green
          text: "Assessment",
          disabled: true,
          icon: <QuizIcon />,
          sx: {}, // Default styles
        };
      case "3":
        return {
          color: "error", // Red
          text: "Assessment Restricted",
          disabled: true,
          icon: <LockIcon />,
          sx: {
            backgroundColor: "red",
            color: "white",
            "&.Mui-disabled": {
              backgroundColor: "red",
              color: "white",
            },
            "&:hover": {
              backgroundColor: "darkred",
            },
          },
        };
      default:
        return {
          color: "primary", // Default color
          text: "Assessment",
          disabled: true,
          icon: <HelpIcon />,
          sx: {}, // Default styles
        };
    }
  };

  if (isData.length === 0) {
    return (
      <div className="flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-50 py-8 px-4">
        <div className="max-w-lg w-full bg-white shadow-xl rounded-2xl p-8 text-center border border-indigo-100">
          <div className="flex flex-col items-center space-y-6">
            <div className="bg-indigo-100 p-4 rounded-full">
              <Calendar className="w-12 h-12 text-indigo-600" />
            </div>

            <div className="space-y-3">
              <h1 className="text-3xl font-bold text-gray-800">
                Cohort 11 is Coming!
              </h1>

              <div className="flex items-center justify-center space-x-2 text-indigo-600">
                <Clock className="w-5 h-5" />
                <p className="text-lg font-medium">Opening December 15th</p>
              </div>
            </div>

            <div className="bg-indigo-50 rounded-lg p-6 w-full">
              <p className="text-gray-700 leading-relaxed">
                Get ready to embark on an exciting journey! Join our upcoming
                cohort and be part of an innovative learning experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container
      sx={{
        marginTop: "10px",
        p: {
          xs: 2, // 16px padding for xs screens (320px+)
          sm: 3, // 24px padding for sm screens
          md: 4, // 32px padding for md screens
          lg: 2, // 48px padding for lg screens
        },
        width: "100%",
        maxWidth: "100% !important", // Override default Container maxWidth
        boxSizing: "border-box",
        overflowX: "auto",
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontWeight: "800" }}
        component="h2"
        gutterBottom
      >
        In-Progress
      </Typography>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item xs={12} sm="auto">
          {/* <TextField
            variant="outlined"
            size="small"
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search..."
            InputProps={{
              endAdornment: searchText && (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> */}
        </Grid>
      </Grid>
      {loading ? (
        <Box sx={{ width: "100%", marginTop: 3 }}>
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
        </Box>
      ) : (
        <Box sx={{ width: "100%", marginTop: 3 }}>
          {/* <Typography
            variant="h5"
            sx={{ fontWeight: "700", textAlign: "center" }}
            component="h3"
            gutterBottom
          >
            {domainHeader}
          </Typography> */}
          <TableContainer
            sx={{
              width: {
                xs: "calc(100vw - 32px)", // Account for container padding
                sm: "100%",
              },
              minWidth: "280px", // Minimum width for smallest screens
              overflowX: "auto",
              "& .MuiTable-root": {
                // Minimum width for table content
                minWidth: {
                  xs: "280px",
                  sm: "100%",
                },
              },
              "& .MuiTableCell-root": {
                // Adjust cell padding for small screens
                padding: {
                  xs: 1,
                  sm: 2,
                },
              },
            }}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#1976d2" }}>
                  <TableCell
                    colSpan={6}
                    sx={{ color: "white", textTransform: "uppercase" }}
                  >
                    INTERNSHIP DOMAIN : {domainHeader} ({cohort})
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.map((row) => (
                  <TableRow key={row.id}>
                    {[26, 27, 28, 36, 37].includes(row.domain_id) ? (
                      <TableCell
                        colSpan={6}
                        align="center"
                        sx={{
                          py: 4,
                          backgroundColor: "#f5f5f5",
                          border: "1px solid #e0e0e0",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <InfoIcon color="primary" sx={{ fontSize: 40 }} />
                          <Typography
                            variant="h6"
                            color="primary"
                            sx={{
                              fontWeight: 500,
                              textAlign: "center",
                              px: 2,
                            }}
                          >
                            This course has been closed for cohort 10 and 9.
                            Access of this course for cohort 11 will begin after
                            1st February 2025.
                          </Typography>
                        </Box>
                      </TableCell>
                    ) : (
                      <>
                        {![1, 3, 8, 7, 33, 38, 24].includes(row.domain_id) && (
                          <TableCell
                            sx={{
                              fontSize: {
                                xs: "8px",
                                sm: "9px",
                                md: "10px",
                              },
                              padding: {
                                xs: "4px",
                                sm: "8px",
                                md: "12px",
                              },
                            }}
                          >
                            <CourseAccessLinkCell
                              courseAccessLink={row.course_access_link}
                              documentsRestrictionStart={
                                domainDetails?.documents_restriction_start
                              }
                              documentsRestrictionEnd={
                                domainDetails?.documents_restriction_end
                              }
                            />
                          </TableCell>
                        )}
                        <TableCell
                          sx={{
                            fontSize: {
                              xs: "8px", // Smaller font size for extra small screens
                              sm: "9px", // Slightly larger font for small screens
                              md: "10px", // Default font size for medium screens and above
                            },
                            padding: {
                              xs: "4px", // Less padding for extra small screens
                              sm: "8px", // Default padding for small screens
                              md: "12px", // Default padding for medium and larger screens
                            },
                          }}
                        >
                          <DocumentAccessCell
                            documentData={{
                              doc_link: row.process_document,
                              documents_restriction_start:
                                domainDetails?.documents_restriction_start,
                              documents_restriction_end:
                                domainDetails?.documents_restriction_end,
                            }}
                            onDocumentClick={() => setIsDocumentModalOpen(true)}
                          />
                        </TableCell>

                        <DocumentModal
                          domainId={row.domain_id}
                          isOpen={isDocumentModalOpen}
                          onClose={() => setIsDocumentModalOpen(false)}
                        />

                        <TableCell
                          sx={{
                            fontSize: {
                              xs: "7px", // Smaller font size for extra small screens
                              sm: "8px", // Slightly larger font for small screens
                              md: "10px", // Default font size for medium screens and above
                            },
                            padding: {
                              xs: "4px", // Less padding for extra small screens
                              sm: "8px", // Default padding for small screens
                              md: "12px", // Default padding for medium and larger screens
                            },
                          }}
                        >
                          {/* Show the recording links icon and text if recordings are available */}
                          {row.record_link && row.record_link.length > 0 && (
                            <Tooltip title="View Recording Links">
                              <Link
                                onClick={() =>
                                  handleRecordingDialogOpen(row.record_link)
                                }
                                sx={{
                                  textDecoration: "none",
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  color: blue[500], // You can style the color as needed
                                }}
                              >
                                <VideoLibraryIcon sx={{ marginRight: 1 }} />
                                Recorded Session Links
                              </Link>
                            </Tooltip>
                          )}
                        </TableCell>

                        {row.domain_id === 33 && (
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 2,
                              }}
                            >
                              <CountdownTimer />
                              <Tooltip title="AccessLink">
                                <Button
                                  variant="contained"
                                  color="info"
                                  size="small"
                                  sx={{ fontSize: "8px" }}
                                  startIcon={
                                    loadingAccessLink ? (
                                      <CircularProgress
                                        size={20}
                                        color="inherit"
                                      />
                                    ) : (
                                      <QuizIcon />
                                    )
                                  }
                                  onClick={handleButtonClick}
                                  disabled={loadingAccessLink}
                                >
                                  {loadingAccessLink
                                    ? "Loading..."
                                    : "Course Access Link"}
                                </Button>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        )}

                        {row.domain_id !== 26 &&
                          row.domain_id !== 27 &&
                          row.domain_id !== 28 &&
                          row.domain_id !== 36 &&
                          row.domain_id !== 37 &&
                          showCertUpload && (
                            <TableCell
                              sx={{
                                fontSize: {
                                  xs: "8px", // Smaller font size for extra small screens
                                  sm: "9px", // Slightly larger font for small screens
                                  md: "10px", // Default font size for medium screens and above
                                },
                                padding: {
                                  xs: "4px", // Less padding for extra small screens
                                  sm: "8px", // Default padding for small screens
                                  md: "12px", // Default padding for medium and larger screens
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip
                                  title={`${
                                    !isAfterTargetDate
                                      ? "Upload not Available"
                                      : "Certificate Upload"
                                  }`}
                                >
                                  <Link
                                    //href="https://internshipadmin.eduskillsfoundation.org/"
                                    //href="https://66ceb19088b5caf41ab43af8--eduskillsfoundationundermentenance.netlify.app/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    color="primary"
                                    sx={{
                                      textDecoration: "none",
                                      display: "flex",
                                      alignItems: "center",
                                      cursor: isAfterTargetDate
                                        ? "pointer"
                                        : "not-allowed",
                                      color: isAfterTargetDate
                                        ? "black"
                                        : "gray",
                                      marginRight: 2, // Add some margin between the link and the circular progress
                                    }}
                                    //onClick={() => navigate("/cert-upload")}
                                    onClick={() =>
                                      isAfterTargetDate &&
                                      navigate("/cert-upload")
                                    }
                                  >
                                    <UploadIcon
                                      sx={{
                                        marginRight: 1,
                                        color: `${
                                          isAfterTargetDate ? "blue" : "gray"
                                        }`,
                                      }}
                                    />
                                    Certificate Upload
                                  </Link>
                                </Tooltip>

                                {/* <Tooltip title={"Certificate Status"}>
                              <Box
                                sx={{
                                  position: "relative",
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <CircularProgress
                                  variant="determinate"
                                  value={row.percent}
                                  size={40}
                                  thickness={5}
                                  sx={{ color: "blue" }}
                                  disabled={!isAfterTargetDate}
                                />
                                <Box
                                  sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    isAfterTargetDate
                                      ? handleDialogOpen(
                                          row.certificateNumber,
                                          row.certificate_status
                                        )
                                      : handleInfoDialogOpen()
                                  }
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color={
                                      isAfterTargetDate
                                        ? "textSecondary"
                                        : "gray"
                                    }
                                    fontWeight="800"
                                  >{`${Math.round(row.percent)}%`}</Typography>
                                </Box>
                              </Box>
                            </Tooltip> */}
                              </Box>
                            </TableCell>
                          )}
                        <TableCell
                          sx={{
                            fontSize: {
                              xs: "8px", // Smaller font size for extra small screens
                              sm: "9px", // Slightly larger font for small screens
                              md: "10px", // Default font size for medium screens and above
                            },
                            padding: {
                              xs: "4px", // Less padding for extra small screens
                              sm: "8px", // Default padding for small screens
                              md: "12px", // Default padding for medium and larger screens
                            },
                          }}
                        >
                          <Tooltip
                            title={getButtonProps(row.assessment_active).text}
                          >
                            <Button
                              variant="contained"
                              color={
                                getButtonProps(row.assessment_active).color
                              }
                              startIcon={
                                getButtonProps(row.assessment_active).icon
                              }
                              disabled={
                                getButtonProps(row.assessment_active).disabled
                              }
                              sx={getButtonProps(row.assessment_active).sx} // Apply custom styles
                              onClick={() => navigate("/assessment")}
                            >
                              {getButtonProps(row.assessment_active).text}
                            </Button>
                          </Tooltip>
                        </TableCell>

                        {/* <TableCell>
                      <Tooltip title="Certificate Download">
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<DownloadIcon />}
                          disabled={!row.certificate_download_status}
                          onClick={() =>
                            window.open(
                              "https://aicte-internship.eduskillsfoundation.org/pages/home/",
                              "_blank"
                            )
                          }
                        >
                          Certificate Download
                        </Button>
                      </Tooltip>
                    </TableCell> */}
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Note Card */}
          {/* <Card sx={{ padding: 1 }}>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", marginRight: 2 }}
                >
                  Note
                  <Typography
                    component="span"
                    sx={{ color: "red", fontWeight: "bold" }}
                  >
                    {" *"}
                  </Typography>
                  :
                </Typography>
                <Typography variant="body1" sx={{ flex: 1, fontWeight: "600" }}>
                  Before accessing the course, please go through the course
                  access document thoroughly.
                </Typography>
              </Box>
            </CardContent>
          </Card> */}
        </Box>
      )}
      {/* <Card className="overflow-hidden p-2">
        <CardContent>
          <Box>
            <Grid container spacing={0}>
              {Array.from({ length: 3 }).map((_, gridIndex) => {
                const start = Math.ceil(importantDates.length / 3) * gridIndex;
                const end =
                  Math.ceil(importantDates.length / 3) * (gridIndex + 1);
                const datesSlice = importantDates.slice(start, end);

                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={gridIndex}
                    className="p-1"
                  >
                    <Table className="w-full">
                      <TableBody>
                        {datesSlice.map((date, index) => (
                          <TableRow
                            key={index}
                            className={`${
                              index % 2 === 0 ? "bg-white" : "bg-gray-100"
                            }`}
                          >
                            <TableCell
                              align="center"
                              sx={{ fontWeight: "bold", fontSize: "10px" }}
                              // className="font-bold border-b border-gray-300 p-1 text-2xl leading-tight"
                            >
                              {date.label}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ fontSize: "10px" }}
                              className="border-b border-gray-300 p-1 text-sm leading-tight"
                            >
                              {date.date}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </CardContent>
      </Card> */}

      {/* New Section: Registration Steps & Doubt Clearing Session */}
      <Card sx={{ padding: 1, marginTop: 1 }}>
        <CardContent>
          <Grid container spacing={2}>
            {/* Steps Section */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: 2 }}
              >
                Steps to Follow
              </Typography>
              <Box
                sx={{
                  bgcolor: "#E6F5FF",
                  padding: {
                    xs: 2,
                    sm: 3,
                    md: 4,
                  },
                  borderRadius: 2,
                  height: {
                    xs: "300px",
                    sm: "350px",
                    md: "400px",
                  },
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#90caf9",
                    borderRadius: "4px",
                    "&:hover": {
                      background: "#64b5f6",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: {
                      xs: 2,
                      sm: 3,
                    },
                  }}
                >
                  {[
                    {
                      step: "1",
                      text: 'Click on the "Course Access Document" to view the process document and complete the course.',
                    },
                    {
                      step: "2",
                      text: "After obtaining all your course completion certificates, click on the 'Certificate Upload' link.",
                    },
                    {
                      step: "3",
                      text: "Verification process will take 5-10 working days.",
                    },
                    {
                      step: "4",
                      text: "Once all certificates are verified, the assessment link will be activated.",
                    },
                    {
                      step: "5",
                      text: "Click on the 'Assessment' link and complete the Final Internship Assessment.",
                    },
                    {
                      step: "6",
                      text: "Once Internship Certificate download link is activated, you will get an email.",
                    },
                  ].map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        bgcolor: "#fff",
                        padding: {
                          xs: 2,
                          sm: 3,
                        },
                        borderRadius: 2,
                        boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                        border: "1px solid #90caf9",
                        display: "flex",
                        gap: 2,
                        alignItems: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: "#1976d2",
                          color: "white",
                          width: {
                            xs: 24,
                            sm: 28,
                          },
                          height: {
                            xs: 24,
                            sm: 28,
                          },
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          fontSize: {
                            xs: 8,
                            sm: 10,
                          },
                          flexShrink: 0,
                        }}
                      >
                        {item.step}
                      </Box>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: 13,
                            sm: 14,
                            md: 16,
                          },
                          flex: 1,
                        }}
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>

            {/* Announcements Section */}
            <Grid item xs={12} md={6}>
              {/* <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: 2 }}
              >
                Announcements
              </Typography>
              <Box
                sx={{
                  bgcolor: "#e3f2fd",
                  padding: 2,
                  borderRadius: 2,
                  height: {
                    xs: "300px",
                    sm: "350px",
                    md: "400px",
                  },
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    overflowY: "auto",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    pr: 1,
                    pt: 2, // Added top padding for NEW badge visibility
                    "&::-webkit-scrollbar": {
                      width: "8px",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                      borderRadius: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#90caf9",
                      borderRadius: "4px",
                      "&:hover": {
                        background: "#64b5f6",
                      },
                    },
                  }}
                > */}
              {/* Important Dates Box */}
              {/* <Box className="bg-blue-100 p-4 md:p-6 rounded-lg border border-blue-200 shadow-sm relative mt-4"> */}
              <AnnouncementsSection
                cohortId={fetchedRows[0]?.cohort_id}
                domainId={fetchedRows[0]?.domain_id}
              />
              {/* <Box className="absolute -top-3 right-2 px-2 py-1 rounded-lg text-xs font-bold bg-red-500 text-white animate-pulse">
                      NEW
                    </Box>

                    <Box className="space-y-3">
                      {importantDates.map((item, index) => (
                        <Box
                          key={index}
                          className="flex flex-col md:flex-row md:items-center md:justify-between"
                        >
                          <Typography
                            sx={{ fontWeight: "700" }}
                            className="text-sm  md:text-base font-semibold"
                          >
                            {item.label}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: "700" }}
                            className="text-sm md:text-base"
                          >
                            {item.date}
                          </Typography>
                        </Box>
                      ))}
                    </Box> */}
              {/* </Box> */}
              {/* Doubt Clearing Session Box */}
              {/* <Box
                    sx={{
                      bgcolor: "#bbdefb",
                      padding: {
                        xs: 1.5,
                        sm: 2,
                      },
                      borderRadius: 2,
                      position: "relative",
                      border: "1px solid #90caf9",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      mt: 1, // Added margin top for NEW badge
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: -12,
                        right: 10,
                        padding: "4px 8px",
                        borderRadius: "8px",
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                        },
                        fontWeight: "bold",
                        backgroundColor: "#f44336",
                        color: "white",
                        animation:
                          "blink 1s ease-in-out infinite, color-change 2s infinite",
                        zIndex: 1,
                        "@keyframes blink": {
                          "0%, 100%": { opacity: 1 },
                          "50%": { opacity: 0.5 },
                        },
                        "@keyframes color-change": {
                          "0%": { backgroundColor: "#f44336" },
                          "25%": { backgroundColor: "#ff9800" },
                          "50%": { backgroundColor: "#ffd700" },
                          "75%": { backgroundColor: "#4caf50" },
                          "100%": { backgroundColor: "#2196f3" },
                        },
                      }}
                    >
                      NEW
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "13px",
                          sm: "14px",
                          md: "16px",
                        },
                        fontWeight: "600",
                      }}
                    >
                      Student Doubt Clearing Session:
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "14px",
                        },
                        mt: 1,
                      }}
                    >
                      <span className="font-bold">Date:</span> 29.10.24 -
                      15.12.24
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "14px",
                        },
                      }}
                    >
                      <span className="font-bold">Time:</span> 3:00 PM - 7:00 PM
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "14px",
                          },
                          fontWeight: "600",
                          display: "inline-block",
                          mr: 1,
                        }}
                      >
                        Meeting Joining Link:
                      </Typography>
                      <Link
                        href="https://us06web.zoom.us/j/83415923265?pwd=ZQ7AkjVLDh3zeHHlNOKfEKRyTSJ0JL.1"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          backgroundColor: "#4caf50",
                          padding: "4px 8px",
                          borderRadius: "8px",
                          fontSize: {
                            xs: "11px",
                            sm: "12px",
                          },
                          display: "inline-block",
                          transition: "all 0.3s ease",
                          "&:hover": {
                            backgroundColor: "#388e3c",
                            transform: "translateY(-1px)",
                          },
                        }}
                      >
                        Join Meeting
                      </Link>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "14px",
                        },
                        mt: 1,
                      }}
                    >
                      <span className="font-bold">Meeting ID:</span> 834 1592
                      3265
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "14px",
                        },
                      }}
                    >
                      <span className="font-bold">Passcode:</span> 390675
                    </Typography>
                  </Box> */}

              {/* Student Briefing Session Box */}
              {/* <Box
                    sx={{
                      bgcolor: "#bbdefb",
                      padding: {
                        xs: 1.5,
                        sm: 2,
                      },
                      borderRadius: 2,
                      position: "relative",
                      border: "1px solid #90caf9",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: -12,
                        right: 10,
                        padding: "4px 8px",
                        borderRadius: "8px",
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                        },
                        fontWeight: "bold",
                        backgroundColor: "#f44336",
                        color: "white",
                        animation:
                          "blink 1s ease-in-out infinite, color-change 2s infinite",
                      }}
                    >
                      NEW
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "13px",
                          sm: "14px",
                          md: "16px",
                        },
                      }}
                    >
                      <span className="font-bold">
                        Student Briefing Session recorded link:
                      </span>{" "}
                      <Link
                        href="https://event.webinarjam.com/go/replay/332/qg1nmuyka6k7b1gmik"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: "#fff",
                          textDecoration: "none",
                          backgroundColor: "#1976d2",
                          padding: "4px 8px",
                          borderRadius: "8px",
                          fontSize: {
                            xs: "11px",
                            sm: "12px",
                          },
                          display: "inline-block",
                          ml: 1,
                          transition: "all 0.3s ease",
                          "&:hover": {
                            backgroundColor: "#1565c0",
                            transform: "translateY(-1px)",
                          },
                        }}
                      >
                        Open Link
                      </Link>
                    </Typography>
                  </Box> */}
              {/* </Box>
              </Box> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Dialog open={openInfoDialog} onClose={handleInfoDialogClose}>
        <DialogTitle>Not Available Yet</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            The Certificate Upload and progress tracking will be available after
            20th October 2024. Please come back after that date.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleInfoDialogClose}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog to show recording links */}
      <Dialog
        open={openRecordingDialog}
        onClose={handleRecordingDialogClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ backgroundColor: blue[500], color: "#fff" }}>
          Recording Links
        </DialogTitle>
        <DialogContent>
          <Table sx={{ marginTop: 2, boxShadow: 3 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: blue[100] }}>
                {/* <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderBottom: "2px solid #ddd" }}
                >
                  Session Name
                </TableCell> */}
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderBottom: "2px solid #ddd" }}
                >
                  Sessions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedRecordings.length > 0 ? (
                selectedRecordings.map((recording, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(even)": { backgroundColor: "#f5f5f5" },
                      "&:nth-of-type(odd)": { backgroundColor: "#ffffff" },
                      "&:hover": { backgroundColor: "#e0e0e0" },
                    }}
                  >
                    {/* <TableCell
                      align="center"
                      sx={{ borderBottom: "1px solid #ddd" }}
                    >
                      {`Session ${index + 1}`}
                    </TableCell> */}
                    <TableCell
                      align="center"
                      sx={{ borderBottom: "1px solid #ddd" }}
                    >
                      {/* Render the link using the parsed href and text */}
                      <Link
                        href={recording.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: blue[500], textDecoration: "none" }}
                      >
                        {recording.text}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    sx={{ color: "#888", padding: "16px" }}
                  >
                    No recordings available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRecordingDialogClose}
            variant="contained"
            color="error"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle sx={{ backgroundColor: blue[500], color: "#fff" }}>
          Certificate Status
        </DialogTitle>
        <DialogContent>
          <Table sx={{ marginTop: 2, boxShadow: 3 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: blue[100] }}>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderBottom: "2px solid #ddd" }}
                >
                  Course Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderBottom: "2px solid #ddd" }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedCertificate.length > 0 ? (
                selectedCertificate.map((cert, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(even)": { backgroundColor: "#f5f5f5" },
                      "&:nth-of-type(odd)": { backgroundColor: "#ffffff" },
                      "&:hover": { backgroundColor: "#e0e0e0" },
                    }}
                  >
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: "1px solid #ddd",
                        paddingRight: "50px",
                      }}
                    >
                      {cert.course_name}
                    </TableCell>
                    <Tooltip title={cert.status}>
                      <TableCell
                        align="center"
                        sx={{
                          borderBottom: "1px solid #ddd",
                          paddingLeft: "50px",
                        }}
                      >
                        {statusIcons[cert.status]}
                      </TableCell>
                    </Tooltip>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    sx={{ color: "#888", padding: "16px" }}
                  >
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            // onClick={() =>
            //   window.open(
            //     "https://internshipadmin.eduskillsfoundation.org/certupload",
            //     "_blank"
            //   )
            // }
            onClick={() => navigate("/cert-upload")}
            variant="contained"
            color="info"
          >
            GO TO CERTIFICATE UPLOAD
          </Button>
          <Button onClick={handleDialogClose} variant="contained" color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
        open={isDialogOpen}
        onClose={handlePdfClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Course Access Document</DialogTitle>
        <DialogContent>
          {pdfUrl ? (
            <iframe
              src={`${pdfUrl}#toolbar=0`}
              title="PDF Viewer"
              width="100%"
              height="600px"
              style={{ border: "none" }}
            />
          ) : (
            "Loading..."
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePdfClose} variant="contained" color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog> */}

      <Dialog
        open={isDialogOpen}
        onClose={handlePdfClose}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: {
              xs: "90%",
              sm: "80%",
              md: "70%",
            },
            margin: {
              xs: 1,
              sm: 2,
              md: 3,
            },
          },
        }}
      >
        <DialogTitle>Course Access Document</DialogTitle>
        <DialogContent
          sx={{
            padding: {
              xs: 2,
              sm: 3,
              md: 4,
            },
          }}
        >
          {pdfUrl ? (
            <Document
              file={{
                url: pdfUrl,
                httpHeaders: {
                  "X-CustomHeader": "CustomValue",
                },
                withCredentials: false,
              }}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) =>
                console.error("Error loading PDF:", error)
              }
              onItemClick={({ pageX, pageY, pageNumber, link }) =>
                onLinkClick({ link })
              } // Handle link clicks
              loading={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: {
                      xs: "200px",
                      sm: "300px",
                      md: "400px",
                    },
                  }}
                >
                  <CircularProgress />
                </Box>
              }
            >
              <Page pageNumber={pageNumber} scale={scale} />
            </Document>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: {
                  xs: "200px", // For small screens
                  sm: "300px", // For medium screens
                  md: "400px", // For larger screens
                },
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center", // Center actions on small screens
            padding: {
              xs: 1, // Less padding for small screens
              sm: 2, // Default padding for medium screens
              md: 3, // More padding for larger screens
            },
            flexWrap: "wrap", // Allow buttons to wrap for smaller screens
            gap: 1, // Add space between buttons
          }}
        >
          <Button onClick={zoomOut} variant="contained" color="info">
            Zoom Out
          </Button>
          <Button onClick={zoomIn} variant="contained" color="info">
            Zoom In
          </Button>
          <Button
            onClick={goToPreviousPage}
            variant="contained"
            color="primary"
            disabled={pageNumber <= 1}
          >
            Previous
          </Button>
          <Typography sx={{ margin: "0 10px" }}>
            Page {pageNumber} of {numPages}
          </Typography>
          <Button
            onClick={goToNextPage}
            variant="contained"
            color="primary"
            disabled={pageNumber >= numPages}
          >
            Next
          </Button>
          <Button onClick={handlePdfClose} variant="contained" color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OngoingTableOld;
