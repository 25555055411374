import React from 'react';
import { AlertCircle } from 'lucide-react';

const PdfWarning = ({ course }) => {
  // Check if domain_id matches the specified values
  if (![29, 30, 31].includes(course.domain_id)) {
    return null;
  }

  return (
    <div className="mb-4 p-4 bg-yellow-50 border-l-4 border-yellow-400 rounded-r">
      <div className="flex items-start">
        <AlertCircle className="h-5 w-5 text-yellow-400 mt-0.5 mr-2 flex-shrink-0" />
        <div>
          <p className="text-sm text-yellow-800">
            <strong className="font-medium">Important:</strong> Only PDF files generated using the 'Save as PDF' option will be accepted. Screenshots or scanned copies will not be valid.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PdfWarning;