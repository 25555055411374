import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

async function getCroppedImg(imageSrc, pixelCrop) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(URL.createObjectURL(blob));
    }, "image/jpeg");
  });
}

const ProfilePhotoSection = ({ register, errors }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [originalFile, setOriginalFile] = useState(null);
  const [tempImagePreview, setTempImagePreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setOriginalFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setTempImagePreview(reader.result);
        setShowModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    try {
      const croppedImageUrl = await getCroppedImg(
        tempImagePreview,
        croppedAreaPixels
      );

      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      const file = new File([blob], originalFile.name, { type: "image/jpeg" });

      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      const fileList = dataTransfer.files;

      register("profile_pic").onChange({ target: { files: fileList } });
      setImagePreview(croppedImageUrl);
      setShowModal(false);
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    if (!imagePreview) {
      const fileInput = document.querySelector('input[type="file"]');
      if (fileInput) {
        fileInput.value = "";
      }
    }
    setTempImagePreview(null);
    setOriginalFile(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  const handleRecrop = () => {
    if (imagePreview) {
      setTempImagePreview(imagePreview);
      setShowModal(true);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-8">
      <div className="bg-gradient-to-r from-blue-800 to-blue-900 p-6">
        <h2 className="text-xl text-white font-semibold tracking-wide">
          Profile Photo
        </h2>
      </div>

      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-4">
            <label className="block font-medium">
              Upload Profile Photo <span className="text-red-500">*</span>
            </label>

            <div className="relative">
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                className="w-full border border-gray-300 p-2 rounded-lg file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-medium file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                {...register("profile_pic", {
                  required: "Profile photo is required",
                  validate: {
                    fileType: (value) =>
                      (value &&
                        ["image/jpeg", "image/jpg", "image/png"].includes(
                          value[0]?.type
                        )) ||
                      "Invalid file type. Only JPG, JPEG, PNG are allowed.",
                    fileSize: (value) =>
                      (value && value[0]?.size <= 1024 * 1024) ||
                      "File size should be less than 1 MB.",
                  },
                })}
                onChange={handleImageChange}
              />

              {errors.profile_pic && (
                <p className="mt-2 text-red-500 text-sm">
                  {errors.profile_pic.message}
                </p>
              )}

              <div className="mt-2 text-sm text-gray-500">
                Accepted formats: JPG, JPEG, PNG (Max size: 1MB)
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="w-48 h-48 overflow-hidden border-4 border-gray-200 bg-gray-50 flex items-center justify-center shadow-lg relative group">
              {imagePreview ? (
                <>
                  <img
                    src={imagePreview}
                    alt="Profile preview"
                    className="w-full h-full object-cover"
                  />
                  <button
                    type="button"
                    onClick={handleRecrop}
                    className="absolute inset-0 bg-black bg-opacity-50 text-white opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center"
                  >
                    Adjust Crop
                  </button>
                </>
              ) : (
                <div className="text-gray-400 text-center p-4">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                  <p className="mt-2">Preview will appear here</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm z-[9999] flex items-center justify-center p-4 md:p-6">
          <div className="bg-white rounded-lg w-full h-full md:h-auto md:max-h-[90vh] md:max-w-5xl flex flex-col">
            <div className="p-4 border-b">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold">Crop Profile Photo</h3>
                <button
                  type="button"
                  onClick={handleCancel}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
            </div>

            <div className="relative flex-1 min-h-[60vh]">
              <Cropper
                image={tempImagePreview}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                showGrid={false}
                objectFit="contain"
              />
            </div>

            <div className="p-4 border-t">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center space-x-4 w-full max-w-md">
                  <button
                    type="button"
                    onClick={() => setZoom(Math.max(1, zoom - 0.1))}
                    className="p-2 rounded hover:bg-gray-100"
                  >
                    -
                  </button>
                  <input
                    type="range"
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    onChange={(e) => setZoom(parseFloat(e.target.value))}
                    className="w-full"
                  />
                  <button
                    type="button"
                    onClick={() => setZoom(Math.min(3, zoom + 0.1))}
                    className="p-2 rounded hover:bg-gray-100"
                  >
                    +
                  </button>
                </div>
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleCrop}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePhotoSection;
