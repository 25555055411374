import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../services/configUrls";
import {
  Calendar,
  Clock,
  Award,
  Upload,
  AlertTriangle,
  Loader2,
  Info,
} from "lucide-react";

const importantDates = [
  {
    label: "Registration Close",
    key: "registration_end_timestamp",
    icon: Clock,
    emoji: "⏰",
  },
  {
    label: "Cert Upload Start",
    key: "certificate_upload_start",
    icon: Upload,
    emoji: "📤",
  },
  {
    label: "Final Certificate Issue",
    key: "certificate_issue_start",
    icon: Award,
    emoji: "🏆",
  },
];

const formatDate = (dateString) => {
  if (
    !dateString ||
    dateString === "0000-00-00 00:00:00" ||
    dateString === "null"
  )
    return "To be announced";

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "To be announced";

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const AnnouncementsSection = ({ cohortId, domainId }) => {
  const [cohortDetails, setCohortDetails] = useState(null);
  const [domainDetails, setDomainDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCohortDetails = async () => {
      if (!cohortId || !domainId) {
        setError("Missing cohort or domain information");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("cohortId", cohortId);
        formData.append("domainId", domainId);

        const response = await fetch(
          `${BASE_URL}/internship/cohortDomainDetails`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch cohort details");
        }

        const data = await response.json();
        if (data.cohortDetails) {
          setCohortDetails(data.cohortDetails);
        }
        if (data.domainDetails) {
          setDomainDetails(data.domainDetails);
        }
      } catch (err) {
        console.error("Error fetching cohort details:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCohortDetails();
  }, [cohortId, domainId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loader2 className="h-12 w-12 text-blue-500 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4 flex items-center gap-2">
        <AlertTriangle className="h-5 w-5 text-red-500" />
        <p className="text-red-600">Failed to load dates: {error}</p>
      </div>
    );
  }

  const shouldShowDocumentAccess =
    domainDetails?.documents_restriction_start &&
    domainDetails?.documents_restriction_end;

  return (
    <div className="h-full">
      <h2 className="text-xl font-bold mb-4 flex items-center gap-2">
        <Info className="h-6 w-6 text-blue-500" />
        Announcements
      </h2>

      <div className="bg-blue-50 p-4 rounded-lg h-96 overflow-hidden">
        <div className="overflow-y-auto h-full pr-2 space-y-3 scrollbar-thin scrollbar-thumb-blue-300 scrollbar-track-gray-100">
          {/* Cohort Information Box */}
          <div className="bg-blue-100 p-4 md:p-6 mt-3 rounded-lg border border-blue-200 shadow-sm relative">
            <div className="absolute -top-3 right-2 px-2 py-1 rounded-lg text-xs font-bold bg-red-500 text-white animate-pulse">
              NEW
            </div>

            {/* {cohortDetails && (
              <div className="mb-4 pb-4 border-b border-blue-200">
                <div className="mt-2 text-sm text-blue-700 space-y-2">
                  <p className="text-sm md:text-base font-semibold text-gray-800 mb-2 flex items-center gap-2">
                    {cohortDetails.cohort_name || `Cohort ${cohortId}`}{" Dates:"}
                  </p>
                  <div className="space-y-2 bg-white/50 p-3 rounded-lg">
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4 text-blue-600" />
                        <p className="text-sm md:text-base text-gray-700">
                          Cohort Starting Date:
                        </p>
                      </div>
                      <p className="text-sm md:text-base font-semibold text-blue-700">
                      {formatDate(cohortDetails.start_date)}
                      </p>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4 text-blue-600" />
                        <p className="text-sm md:text-base text-gray-700">
                          Cohort Ending Date:
                        </p>
                      </div>
                      <p className="text-sm md:text-base font-semibold text-blue-700">
                      {formatDate(cohortDetails.end_date)}
                      </p>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4 text-blue-600" />
                        <p className="text-sm md:text-base text-gray-700">
                          Cohort Extended Date:
                        </p>
                      </div>
                      <p className="text-sm md:text-base font-semibold text-blue-700">
                      {formatDate(cohortDetails.extend_date)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )} */}

            <div className="space-y-4">
              {shouldShowDocumentAccess && (
                <div className="mb-4">
                  <p className="text-sm md:text-base font-semibold text-gray-800 mb-2 flex items-center gap-2">
                    <AlertTriangle className="h-5 w-5 text-yellow-500" />
                    Important: Course Materials Access Restricted During:
                  </p>
                  <div className="space-y-2 bg-yellow-50/50 p-3 rounded-lg">
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4 text-yellow-600" />
                        <p className="text-sm md:text-base text-gray-700">
                          Restriction Starts:
                        </p>
                      </div>
                      <p className="text-sm md:text-base font-semibold text-blue-700">
                        {formatDate(domainDetails.documents_restriction_start)}
                      </p>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4 text-yellow-600" />
                        <p className="text-sm md:text-base text-gray-700">
                          Restriction Ends:
                        </p>
                      </div>
                      <p className="text-sm md:text-base font-semibold text-blue-700">
                        {formatDate(domainDetails.documents_restriction_end)}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {/* {shouldShowDocumentAccess && ( */}
                <div className="mb-4">
                  <p className="text-sm md:text-base font-semibold text-gray-800 mb-2 flex items-center gap-2">
                    {/* <AlertTriangle className="h-5 w-5 text-yellow-500" /> */}
                    Cohort Registration Duration:
                  </p>
                  <div className="space-y-2 bg-yellow-50/50 p-3 rounded-lg">
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4 text-yellow-600" />
                        <p className="text-sm md:text-base text-gray-700">
                          Registration Starts:
                        </p>
                      </div>
                      <p className="text-sm md:text-base font-semibold text-blue-700">
                        {formatDate(cohortDetails.registration_start_timestamp)}
                      </p>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4 text-yellow-600" />
                        <p className="text-sm md:text-base text-gray-700">
                          Registration Ends:
                        </p>
                      </div>
                      <p className="text-sm md:text-base font-semibold text-blue-700">
                        {formatDate(cohortDetails.registration_end_timestamp)}
                      </p>
                    </div>
                  </div>
                </div>
              {/* )} */}

              <div className="mb-4">
                <p className="text-sm md:text-base font-semibold text-gray-800 mb-2 flex items-center gap-2">
                  {/* <AlertTriangle className="h-5 w-5 text-yellow-500" /> */}
                  Certificate Upload Duration:
                </p>
                <div className="space-y-2 bg-yellow-50/50 p-3 rounded-lg">
                  <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                    <div className="flex items-center gap-2">
                      <Clock className="h-4 w-4 text-yellow-600" />
                      <p className="text-sm md:text-base text-gray-700">
                        Access Starts:
                      </p>
                    </div>
                    <p className="text-sm md:text-base font-semibold text-blue-700">
                      {formatDate(cohortDetails.certificate_upload_start)}
                    </p>
                  </div>
                  {cohortDetails.certificate_upload_end === null ? null : (
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4 text-yellow-600" />
                        <p className="text-sm md:text-base text-gray-700">
                          Access Ends:
                        </p>
                      </div>
                      <p className="text-sm md:text-base font-semibold text-blue-700">
                        {formatDate(cohortDetails.certificate_upload_end)}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* {shouldShowDocumentAccess && ( */}
              <div className="mb-4">
                <p className="text-sm md:text-base font-semibold text-gray-800 mb-2 flex items-center gap-2">
                  {/* <AlertTriangle className="h-5 w-5 text-yellow-500" /> */}
                  Final Certificate Issue Duration:
                </p>
                <div className="space-y-2 bg-yellow-50/50 p-3 rounded-lg">
                  <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                    <div className="flex items-center gap-2">
                      <Clock className="h-4 w-4 text-yellow-600" />
                      <p className="text-sm md:text-base text-gray-700">
                        Certificate Issue Starts:
                      </p>
                    </div>
                    <p className="text-sm md:text-base font-semibold text-blue-700">
                      {formatDate(cohortDetails.certificate_issue_start)}
                    </p>
                  </div>
                  {cohortDetails.certificate_issue_end === null ? null : (
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4 text-yellow-600" />
                        <p className="text-sm md:text-base text-gray-700">
                          Certificate Issue Ends:
                        </p>
                      </div>
                      <p className="text-sm md:text-base font-semibold text-blue-700">
                        {formatDate(cohortDetails.certificate_issue_end)}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="border-t border-blue-200 pt-4">
                {importantDates.map((item, index) => {
                  const IconComponent = item.icon;
                  return (
                    <div
                      key={index}
                      className="flex flex-col md:flex-row md:items-center md:justify-between gap-2 bg-white/50 p-3 rounded-lg mb-3"
                    >
                      <div className="flex items-center gap-2">
                        <IconComponent className="h-5 w-5 text-blue-600" />
                        
                        <p className="text-sm md:text-base font-semibold text-gray-800">
                          {item.label}:
                        </p>
                      </div>
                      <p className="text-sm md:text-base font-semibold text-blue-700">
                        {cohortDetails && formatDate(cohortDetails[item.key])}
                      </p>
                    </div>
                  );
                })}
              </div> */}
            </div>
          </div>

          {!cohortDetails && (
            <div className="flex justify-center items-center h-full">
              <Info className="h-5 w-5 text-gray-500 mr-2" />
              <p className="text-gray-500">No announcements available</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnouncementsSection;
