import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { getOngoingInternships } from "../services/dataService";

const CACHE_KEY = "internshipData";
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const DomainGuard = ({ children, type }) => {
  const [isAllowed, setIsAllowed] = useState(null);

  useEffect(() => {
    const checkDomainAccess = async () => {
      try {
        // Check localStorage for cached data
        const cachedData = localStorage.getItem(CACHE_KEY);
        let internshipData;

        if (cachedData) {
          const { data, timestamp } = JSON.parse(cachedData);
          const isExpired = Date.now() - timestamp > CACHE_DURATION;

          if (!isExpired) {
            internshipData = data;
          }
        }

        // If no cached data or expired, fetch from API
        if (!internshipData) {
          const response = await getOngoingInternships.ongoingInternshipsData();
          internshipData = response.data;

          // Cache the new data
          localStorage.setItem(
            CACHE_KEY,
            JSON.stringify({
              data: internshipData,
              timestamp: Date.now(),
            })
          );
        }

        // First check domain restrictions
        const restrictedDomains =
          type === "assessment"
            ? [13, 25, 9, 6, 20, 26, 27, 28, 36, 37]
            : [26, 27, 28, 36, 37];

        const hasRestrictedDomain = internshipData.internships?.some(
          (internship) => restrictedDomains.includes(internship.domain_id)
        );

        // For cert-upload, check specifically for cohort_id 11
        if (type === "cert-upload") {
          const hasOnlyCohort11 = internshipData.internships?.every(
            (internship) => internship.cohort_id === 11
          );

          // Allow access if there's at least one internship not in cohort 11
          setIsAllowed(!hasRestrictedDomain && !hasOnlyCohort11);
          return;
        }

        setIsAllowed(!hasRestrictedDomain);
      } catch (error) {
        console.error("Domain check failed:", error);
        setIsAllowed(false);
      }
    };

    checkDomainAccess();
  }, [type]);

  if (isAllowed === null) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return isAllowed ? children : <Navigate to="/internship" replace />;
};

export default DomainGuard;
