import { Icon } from "@iconify/react";
import { CorporateFare } from "@mui/icons-material";
// import { AutoMode, Book, CorporateFare } from "@mui/icons-material";
// -----------------------------------------------------------------
const navConfig = [
  // {
  //   title: "Dashboard",
  //   path: "/dashboard",
  //   icon: <Icon icon="mingcute:classify-2-fill" height={22} width={22} />,
  // },
  // {
  //   title: "Profile",
  //   path: "/profile",
  //   icon: <Icon icon="iconamoon:profile" height={22} width={22} />,
  // },

  // {
  //   title: "FAQ",
  //   path: "/internship/faq",
  //   icon: <Icon icon="mdi:frequently-asked-questions" height={22} width={22} />,
  // },

  {
    title: "Internships",
    path: "/internship",
    icon: <Icon icon="vaadin:academy-cap" height={22} width={22} />,
    children: [
      {
        title: "My Internships",
        path: "/internship",
        icon: (
          <Icon
            icon="vscode-icons:folder-type-certificate-opened"
            height={22}
            width={22}
          />
        ),
      },
      // {
      //   title: "Certificate Upload",
      //   path: "/cert-upload",
      //   icon: <Icon icon="mdi:certificate" height={22} width={22} />,
      // },
      // {
      //   title: "Certificate Varification",
      //   path: "/internship/certificate/varification",
      //   icon: <Icon icon="mdi:certificate" height={22} width={22} />,
      // },
      {
        title: "Certificate",
        path: "/internship/certificate",
        icon: <Icon icon="mdi:certificate" height={22} width={22} />,
      },
      // {
      //   title: "FAQ",
      //   path: "/internship/faq",
      //   icon: <Icon icon="mdi:certificate" height={22} width={22} />,
      // },

      // {
      //   title: "Apply Internship",
      //   path: "/internship/registration",
      //   icon: <Icon icon="carbon:course" height={22} width={22} />,
      // },
      // {
      //   title: "Courses1",
      //   path: "/internship/coursess",
      //   icon: <Icon icon="carbon:course" height={22} width={22} />,
      // },
    ],
  },
  // {
  //   title: "Assessment",
  //   path: "/assessment",
  //   icon: <Icon icon="carbon:badge" height={22} width={22} />,
  // },
  {
    title: "Talent Connect",
    path: "/jobs/opening",
    icon: <Icon icon="carbon:batch-job" height={22} width={22} />,
    children: [
      {
        title: "Opening Jobs",
        path: "/jobs/opening",
        icon: <Icon icon="arcticons:jobstreet" height={22} width={22} />,
      },
      {
        title: "Applied Jobs",
        path: "/jobs/applied",
        icon: (
          <Icon icon="oui:ml-create-single-metric-job" height={22} width={22} />
        ),
      },
    ],
  },

  // {
  //   title: "FAQ",
  //   path: "/internship/faq",
  //   icon: <Icon icon="mdi:certificate" height={22} width={22} />,
  // },

  // {
  //   title: "Student",
  //   path: "/student",
  //   icon: <Icon icon="ph:student" height={22} width={22} />,
  // },
  // {
  //   title: "Performance",
  //   path: "/performance",
  //   icon: <Icon icon="eos-icons:performance" height={22} width={22} />,
  // },
  // {
  //   title: "Academy Program",
  //   path: "/academy-program",
  //   icon: <Icon icon="mdi:google-classroom" height={22} width={22} />,
  // },
  // // Admin....................................
  // {
  //   title: "Institute",
  //   path: "/institute",
  //   icon: <Icon icon="fa:university" height={22} width={22} />,
  // },
  // {
  //   title: "Academy",
  //   path: "/academy",
  //   icon: <Icon icon="ic:round-corporate-fare" height={22} width={22} />,
  // },
  // {
  //   title: "Educators",
  //   path: "/admin-educator",
  //   icon: <Icon icon="ph:chalkboard-teacher-bold" height={22} width={22} />,
  // },
  // //............................................
  // {
  //   title: "Awards & Recognition",
  //   path: "/awards-recognition",
  //   icon: <Icon icon="healthicons:award-trophy" height={25} width={25} />,
  // },
  // {
  //   title: "Institution Status",
  //   path: "/institution-status",
  //   icon: <AutoMode />,
  // },
  // {
  //   title: "Corporate",
  //   path: "/corporate-program",
  //   icon: <Icon icon="healthicons:i-training-class" height={25} width={25} />,
  // },
  // {
  //   title: "Educator",
  //   path: "/educator",
  //   icon: <Icon icon="icon-park-solid:classroom" height={22} width={22} />,
  // },
  // {
  //   title: "Membership",
  //   path: "/membership",
  //   icon: (
  //     <Icon icon="material-symbols:card-membership" height={22} width={22} />
  //   ),
  //   children: [
  //     {
  //       title: "Agreements",
  //       path: "/membership/agreements",
  //       parent: "Membership",
  //       icon: (
  //         <Icon icon="icon-park-twotone:agreement" height={22} width={22} />
  //       ),
  //     },
  //     // {
  //     //   title: "Payment",
  //     //   path: "/membership/payment",
  //     //   parent: "Membership",
  //     //   icon: <Icon icon="mdi:account-payment" height={22} width={22} />,
  //     // },
  //     {
  //       title: "Instituational Details",
  //       path: "/membership/institutional-details",
  //       parent: "Membership",
  //       icon: <Icon icon="icomoon-free:profile" height={22} width={22} />,
  //     },
  //   ],
  // },
  // {
  //   title: "News & Events",
  //   path: "/news-events",
  //   icon: (
  //     <Icon icon="material-symbols:event-note-rounded" height={22} width={22} />
  //   ),
  // },
  // {
  //   title: "Publication",
  //   path: "/publication",
  //   icon: <Book />,
  // },
  // {
  //   title: "Talent Connect",
  //   path: "/talent-connect",
  //   icon: <Icon icon="icon-park-solid:connect" height={22} width={22} />,
  // },
  // {
  //   title: "Company",
  //   // path: "/company",
  //   icon: <Icon icon="mdi:company" height={22} width={22} />,
  //   children: [
  //     {
  //       title: "All Company",
  //       path: "/company",
  //       icon: <Icon icon="clarity:building-line" height={22} width={22} />,
  //     },
  //     {
  //       title: "Add Company",
  //       path: "/company",
  //       icon: <Icon icon="material-symbols:add-box" height={22} width={22} />,
  //     },
  //     {
  //       title: "All Job",
  //       path: "/company",
  //       icon: (
  //         <Icon icon="icon-park-outline:network-tree" height={22} width={22} />
  //       ),
  //     },
  //     {
  //       title: "Add Job",
  //       path: "/company",
  //       icon: <Icon icon="mdi:tab-add" height={22} width={22} />,
  //     },
  //     {
  //       title: "Publish Job",
  //       path: "/company",
  //       icon: <Icon icon="entypo:publish" height={22} width={22} />,
  //     },
  //   ],
  // },
  // {
  //   title: "Resume",
  //   path: "/resume",
  //   icon: <Icon icon="pepicons-pop:cv" height={22} width={22} />,
  // },

  // {
  //   title: "Placement",
  //   path: "/placement",
  //   icon: <Icon icon="ic:twotone-work" height={22} width={22} />,
  //   parent: "Placement",
  //   children: [
  //     {
  //       title: "Resume",
  //       path: "/placement",
  //       icon: <CorporateFare />,
  //     },
  //     {
  //       title: "Job Details",
  //       path: "/placement",
  //       icon: <CorporateFare />,
  //     },
  //     {
  //       title: "Interview",
  //       path: "/placement",
  //       icon: <CorporateFare />,
  //     },
  //   ],
  // },
  // {
  //   title: "Support",
  //   path: "/support",
  //   icon: (
  //     <Icon icon="fluent:person-support-20-filled" height={22} width={22} />
  //   ),
  // },
  // {
  //   title: "Report",
  //   // path: "/report",
  //   icon: <Icon icon="mdi:report-box" height={22} width={22} />,
  // children: [
  // {
  //   title: "Student",
  //   path: "/payment",
  //   icon: <CorporateFare />,
  // },
  //   {
  //     title: "Resume",
  //     path: "/report",
  //     icon: <CorporateFare />,
  //   },
  // ],
  // },
];

const roleTitles = {
  // spoc: [
  //   "Dashboard",
  //   "Internship",
  //   "Corporate",
  //   "Educator",
  //   // "Awards & Recognition",
  //   // "News & Events",
  //   // "Talent Connect",
  //   // "Publication",
  //   "Membership",
  //   // "Support",
  // ],
  // management: [
  //   "Dashboard",
  //   "Internship",
  //   "Educator",
  //   "Corporate",
  //   "Membership",
  // ],
  // educator: ["Dashboard", "Internship", "Academy Program", "Support"],
  // manager: [
  //   "Dashboard",
  //   "Internship",
  //   "College List",
  //   "Academy Program",
  //   "Institutional Details",
  //   "Academy",
  // ],
  // account_manager: [
  //   "Dashboard",
  //   "Internship",
  //   "College List",
  //   "Academy Program",
  //   "Institutional Details",
  // ],
  // talent_module: [
  //   "Dashboard",
  //   "Company",
  //   "Placement",
  //   "Resume",
  //   "Report",
  //   "Support",
  // ],
  // admin: [
  //   "Dashboard",
  //   // "Internship",
  //   "Institute",
  //   // "Academy Program",
  //   // "Institutional Details",
  //   "Educators",
  //   "Academy",
  // ],
  // staff: [
  //   "Dashboard",
  //   // "Internship",
  //   "Institute",
  //   // "Academy Program",
  //   // "Institutional Details",
  //   "Educator",
  //   // "Academy",
  // ],
  // development: ["Dashboard", "Student", "Assessment"],
  // student: [
  //   "Dashboard",
  //   "Internships",
  //   "Talent Connect",
  //   "Assessment",
  //   "My Profile",
  // ],
  // student: ["Assessment"],
  student: ["Talent Connect", "Profile", "Internships", "FAQ"],
};

const filterNavConfig = (roles) => {
  const allowedTitles = [];
  // for (const role in roles) {
  //   if (roles[role]) {
  //     allowedTitles?.push(...roleTitles[role]);
  //   }
  // }
  // Check if roles is an object and not null or undefined
  if (roles && typeof roles === "object") {
    for (const role in roles) {
      if (roles.hasOwnProperty(role) && roles[role]) {
        // Check for role existence and truthiness
        if (roleTitles && roleTitles[role]) {
          allowedTitles.push(...roleTitles[role]);
        }
      }
    }
  }

  const menuItems = navConfig
    .filter((item) => allowedTitles.includes(item.title))
    .map((item) => ({
      title: item.title,
      path: item.path,
      icon: item.icon,
      children: item.children,
    }));

  return menuItems;
};

export const MenuItems = () => {
  const authorise = localStorage.getItem("Authorise");
  const roles = {
    admin: authorise === "admin" ? true : false,
    staff: authorise === "staff" ? true : false,
    spoc: authorise === "spoc" ? true : false,
    manager: authorise === "manager" ? true : false,
    management: authorise === "management" ? true : false,
    educator: authorise === "educator" ? true : false,
    account_manager: authorise === "account_manager" ? true : false,
    talent_module: authorise === "talent_module" ? true : false,
    student: authorise === "student" ? true : false,
  };

  return filterNavConfig(roles);
};
