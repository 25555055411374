import React from "react";
import { Link, Tooltip } from "@mui/material";
import { MdAssessment } from "react-icons/md";

export const CourseAccessLinkCell = ({
  courseAccessLink,
  documentsRestrictionStart,
  documentsRestrictionEnd,
}) => {
  const isAccessRestricted = () => {
    const currentDate = new Date();
    const startDate = new Date(documentsRestrictionStart);
    const endDate = new Date(documentsRestrictionEnd);

    return currentDate >= startDate && currentDate <= endDate;
  };

  return (
    <Tooltip
      title={
        isAccessRestricted()
          ? "Access Restricted During This Period"
          : "Course Access Link"
      }
    >
      <span>
        {" "}
        {/* Wrapper span for disabled state tooltip */}
        <Link
          href={courseAccessLink || "#"}
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
          sx={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            cursor: isAccessRestricted() ? "not-allowed" : "pointer",
            opacity: isAccessRestricted() ? 0.5 : 1,
            pointerEvents: isAccessRestricted() ? "none" : "auto",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          onClick={(e) => {
            if (isAccessRestricted() || !courseAccessLink) {
              e.preventDefault();
            }
          }}
        >
          <MdAssessment
            style={{
              fontSize: "20px",
              color: isAccessRestricted() ? "gray" : "blue",
              marginRight: "8px",
            }}
          />
          Course Access Link
        </Link>
      </span>
    </Tooltip>
  );
};

export default CourseAccessLinkCell;
